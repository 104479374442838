/* TODO: remove the css when get conditional css working */
body {
    background: #99ddff !important;
}
/* END TODO */

.AppContainer {
    background: #f2f4f8;
}

.sfa-sideBar {
    background: #ffffff;
    box-shadow: 0 0 30px 0 rgba(72, 130, 176, 0.4);
    min-width: 300px;
}

.mobile-app-bar {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding: 5px;
    z-index: 1;
    /* position:fixed; */
    left: 0;
    top: 0;
    width: 100%;
}

.mobile-footer {
    opacity: 0.7;
    background: #0c0033;
    height: 37px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: fixed;
    left: 0;
    bottom: 0 !important;
}

.mobile-footer > span {
    color: #ffffff;
}

.menuText {
    font-family: 'Open Sans', serif;
    font-size: 14px;
    color: #0c0033;
    letter-spacing: 0;
    line-height: 20px;
}

.sideBarCenteredImage {
    vertical-align: middle;
    align-self: center;
    max-width: 250px;
}

.sideBarImageWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100px;
    box-shadow: inset 0 -1px 0 0 rgba(216, 216, 216, 0.5);
}

.appBarImage {
    height: 29px;
    width: 144px;
    align-self: center;
}

.appBarImageWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 50px;
}

.app-bar button svg {
    fill: #306392 !important;
}

.side-nav-btn {
    background-color: transparent !important;
    padding: 0 0 0 10px !important;
}

.profile-section {
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: inset 0 -1px 0 0 rgba(216, 216, 216, 0.5);
    margin-bottom: 24px;
}

.profile-section span.fullName {
    font-size: 14px;
    color: #0c0033;
    letter-spacing: 0;
    line-height: 20px;
    align-self: center;
}

.profile-section span.username {
    opacity: 0.5;
    font-size: 12px;
    color: #0c0033;
    line-height: 16px;
    margin-bottom: 15px;
    align-self: center;
}

.profile-section .UserAvatar {
    color: white;
}

.profile-section .UserAvatar--inner {
    font-size: 16px;
    font-weight: bold;
    border: 1px #0c0033 solid;
}

.profile-section div.avatar {
    line-height: 56px;
    max-height: 56px;
    max-width: 56px;
    border: 1px #0c0033 solid;
    border-radius: 100%;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.ui.vertical.sfa-menu.menu {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.ui.vertical.sfa-menu.menu .item.disabled {
    opacity: 0.5;
}

.mobile-sidebar {
    height: 100%;
    z-index: 10;
    background-color: #ffffff;
    position: relative;
}

.mobile-sidebar > div.sidebar-actions {
    padding: 10px 10px 10px 17px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mobile-sidebar > div.sidebar-actions > div.close {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.mobile-sidebar > div.sidebar-actions > div.close > span {
    font-size: 18px;
}

.mobile-sidebar > div.sidebar-actions > .ui.compact.button {
    background: #6982a6;
    color: #ffffff;
    padding: 0 5px;
    height: 24px;
    width: 67px;
    margin: 0;
}

.mobile-sidebar > div.mobile-profile-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    margin: 10px 0 15px;
}

.mobile-sidebar > div.mobile-profile-section .UserAvatar--inner {
    border: 1px #0c0033 solid;
}

.mobile-sidebar > div.mobile-profile-section > span.fullName {
    margin-left: 12px;
    font-size: 16px;
}
.ui.button.sign-out:hover {
    opacity: 1;
    background: #3599db;
    color: white;
}
@media only screen and (min-width: 992px) {
    .AppContainer {
        background: #f2f4f8;
        opacity: 0.98;
        box-shadow: 0 0 30px 0 rgba(72, 130, 176, 0.4);
        min-height: 100vh;
        width: 95%;
        vertical-align: middle;
        margin: 35px;
        text-align: left;
    }

    .AppContainer.offline {
        border: 5px solid #ff0048;
    }

    .AppContainer .offline-status-wrapper {
        display: none;
        position: absolute;
        top: 45px;
        right: 0;
        margin-right: 3%;
    }

    .AppContainer.offline .offline-status-wrapper {
        display: inline;
    }

    .AppHolder {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
    }

    .contentArea {
        flex: 1;
        text-align: left;
        margin: 0 20px;
    }

    .sideBarImageWrapper > .build-info {
        display: flex;
        margin-top: 10px;
        justify-content: center;
        align-items: flex-end;
        font-size: 11px;
        opacity: 0.7;
    }

    .AppContainer .AppHolder.pushable {
        height: 100%;
        min-height: 100vh;
    }
}

@media only screen and (max-width: 991px) {
    .AppContainer {
        width: 100%;
        height: 100%;
    }
    .dashboard .ui.segment {
        height: auto !important;
    }
    .dashboard .ui.segment:last-child {
        margin-bottom: 10px;
    }

    .AppContainer.offline > .AppHolder {
        border: 5px solid #ff0048;
    }

    .AppHolder {
        width: 100%;
        height: 100% !important;
    }

    .contentArea {
        text-align: left;
        background-color: transparent !important;
        opacity: 0.98;
        margin-top: 0;
    }

    .contentArea > #page-container {
        background: #f2f4f8 !important;
        padding: 16px 16px calc(10vh - 1px);
        margin-top: 16px;
        margin-bottom: 0;
        min-height: 100vh; /* have container stretch to bottom of page */
    }

    .contentArea .offline-status-wrapper {
        display: none;
        top: 70px;
        position: absolute;
        right: 8px;
    }

    .contentArea.offline .offline-status-wrapper {
        display: inline;
    }

    .mobile-sidebar > .build-info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-left: 15px;
        font-size: 11px;
        opacity: 0.7;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}
