.appLogo {
    height: 30px;
    width: 180px;
    align-self: center;
}

.logo {
    height: 80px;
    width: 74px;
    align-self: center;
}
