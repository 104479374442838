.checkout-page .content {
    background: #ffffff;
    padding: 14px;
}

.checkout-page > .sfa-page {
    width: 100%;
    margin: 35px 0 0 0;
}
.checkout-page .place-order-button {
    min-width: 123px;
}
.checkout-page .place-order-button.loading {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.ui.dropdown .menu.cart-menu {
    min-width: auto !important;
    right: 0;
    left: unset;
}

.checkout-page .totals-wrapper,
.checkout-page .game-name-ref .cart-menu .cart-header,
.order-detail-page .totals-wrapper,
.order-detail-page .game-name-ref .cart-menu .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f4f8;
    padding: 10px;
}

.ui.menu .ui.dropdown .menu > .item.cart-menu-item-wrapper {
    padding: 0 !important;
}

.ui.menu .ui.dropdown .menu > .item.cart-menu-item-wrapper:hover {
    background: transparent !important;
}

.checkout-page {
    padding: 10px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.checkout-page .game-item,
.order-detail-page .game-item {
    margin-bottom: 7px;
}

.checkout-page .game-item .game-info-wrapper,
.checkout-page .game-item,
.order-detail-page .game-item .game-info-wrapper,
.order-detail-page .game-item {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.checkout-page .total-quantity-wrapper > div {
    display: inline-block;
}

.checkout-page .total-quantity-wrapper > .label {
    width: 192px;
    text-align: right;
}

.checkout-page .total-quantity-wrapper > .total-quantity {
    width: 70px;
    padding-left: 34px;
}

.checkout-page .checkout-wrapper {
    text-align: center;
}

.checkout-page .remove-game-wrapper {
    padding: 5px;
}

.checkout-page .suggested-quantity-warning-wrapper {
    padding: 2px;
}

.checkout-page .suggested-quantity-header {
    margin-left: 515px;
}

.suggested-quantity-data {
    margin-left: -90px;
    margin-right: 45px;
}

.exclamation-icon-visibility {
    visibility: hidden;
}

.checkout-page .game-item .game-info-wrapper,
.order-detail-page .game-item .game-info-wrapper {
    padding: 14px 14px 14px 0;
}

.checkout-page .remove-game-wrapper i.icon {
    opacity: 0.5;
}

.checkout-page .remove-game-wrapper i.icon:hover {
    opacity: 1;
    color: #de1212 !important;
}

.checkout-page .total-quantity-wrapper > div,
.checkout-page .game-item .game-info-wrapper,
.order-detail-page .total-quantity-wrapper > div {
    background: #e8f1fc;
}

.checkout-page .total-quantity-wrapper > div {
    padding: 7px 0;
}

.checkout-page .game-item .game-info-wrapper,
.order-detail-page .game-item .game-info-wrapper {
    border-radius: 3px;
}

.checkout-page .total-quantity-wrapper > div:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.checkout-page .total-quantity-wrapper > div:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.checkout-page .game-item .game-info-wrapper .game-name-ref {
    word-break: break-word;
    white-space: normal;
}

.checkout-page .change-quantity-action-wrapper {
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
}

.checkout-page .change-quantity-action-wrapper i.icon {
    margin: 0 !important;
}

.checkout-page .change-quantity-action-wrapper .quantity {
    padding: 0 14px;
}

.checkout-page .game-item .game-info-wrapper,
.order-detail-page .game-item .game-info-wrapper {
    width: 100%;
}

.checkout-page .game-info .line-number {
    display: inline-block;
    width: 30px;
    text-align: right;
    margin-right: 10px;
}

.checkout-page .sample-checkbox-wrapper {
    margin: 10px 0 0 40px;
}

.checkout-page .ui.button.transparent-btn {
    color: #0c0033;
    font-weight: 600;
}

.checkout-page .totals-wrapper div,
.order-detail-page .totals-wrapper div {
    display: inline-block;
}

.checkout-page .totals-wrapper .total-samples,
.order-detail-page .totals-wrapper .total-samples {
    margin-left: 35px;
}

.checkout-page .totals-wrapper {
    width: 94.3% !important;
    max-width: 909px;
    padding: 14px 7px;
}

.checkout-page .totals-wrapper .total-quantity .value,
.order-detail-page .totals-wrapper .total-quantity .value {
    width: 75px;
    text-align: center;
}

.checkout-page .remove-all-link-wrapper {
    text-align: left;
}

.checkout-page .remove-all-link {
    text-decoration: underline;
    padding: 7px 0;
    display: inline-block;
}

.checkout-page .actions {
    text-align: right;
    padding-right: 5.5%;
    padding-bottom: 14px;
}

.checkout-page .content .last-order-wrapper {
    text-align: right;
    margin-top: -35px;
    padding-bottom: 35px;
}

.checkout-page .content .last-order-wrapper .order-number {
    text-decoration: underline;
}

.checkout-page .game-total-wrapper,
.order-detail-page .game-total-wrapper {
    width: 15%;
    display: flex;
    justify-content: space-between;
}

.checkout-page .game-info-wrapper .total-cost-wrapper {
    display: inline-block;
}

.checkout-page .totals-wrapper.header-wrapper,
.order-detail-page .totals-wrapper.header-wrapper {
    background: transparent;
}

.checkout-page .totals-wrapper.header-wrapper .total-quantity,
.order-detail-page .totals-wrapper.header-wrapper .total-quantity {
    width: 14%;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}

.checkout-page
    .totals-wrapper
    .total-quantity
    .value.total-cost.bold.min-tablet {
    padding-left: 37px;
}

@media screen and (max-width: 767px) {
    .checkout-page .content .last-order-wrapper {
        margin-top: 0;
    }

    .checkout-page .total-quantity-wrapper > .label,
    .order-detail-page .total-quantity-wrapper > .label {
        width: 141px;
    }

    .checkout-page .total-quantity-wrapper > .total-quantity,
    .order-detail-page .total-quantity-wrapper > .total-quantity {
        width: 80px;
        padding-left: 30px;
    }

    .checkout-page .totals-wrapper > div,
    .order-detail-page .totals-wrapper > div {
        padding: 7px 0;
    }

    .checkout-page .totals-wrapper div,
    .checkout-page .totals-wrapper,
    .order-detail-page .totals-wrapper div,
    .order-detail-page .totals-wrapper {
        display: block;
        text-align: left;
    }

    .checkout-page .totals-wrapper .total-quantity > div,
    .order-detail-page .totals-wrapper .total-quantity > div {
        display: inline-block;
    }

    .checkout-page .totals-wrapper .total-quantity .value,
    .order-detail-page .totals-wrapper .total-quantity .value {
        width: auto;
        padding-left: 10px;
    }

    .checkout-page .totals-wrapper .total-samples,
    .order-detail-page .totals-wrapper .total-samples {
        margin-left: 0;
    }

    .checkout-page .actions > button {
        display: block;
        margin: 7px auto;
        width: 100%;
    }

    .checkout-page .game-total-wrapper,
    .checkout-page .game-item .game-info-wrapper,
    .order-detail-page .game-total-wrapper,
    .order-detail-page .game-item .game-info-wrapper {
        flex-direction: column;
    }

    .checkout-page .change-quantity-action-wrapper {
        margin-top: 15px;
    }
    .checkout-page .ui.button.transparent-btn {
        padding: 0;
        line-height: 20px;
    }
}

@media screen and (max-width: 991px) {
    .checkout-page .sfa-page {
        margin-top: 10px;
    }
}

@media screen and (min-width: 992px) {
    .checkout-page {
        max-width: 991px;
        margin: auto;
    }
}
