.date-style {
    font-size: 16px;
    line-height: 24px;
    color: #0c0033 !important;
}
.config-field-edit {
    width: 100%;
}
.config-label {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 5px;
    color: #0c0033;
}

.combo-label {
    margin-top: 8px;
    height: 40px;
    display: inline-flex;
    align-items: center;
}
