.contact {
    font-weight: bold !important;
    width: 10%;
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;
}

.contact-value {
    font-weight: normal !important;
    display: inline-block;
}

.contact-body {
    text-align: left !important;
}

/* mobile */
@media only screen and (max-width: 767px) {
    .contact {
        width: auto;
        display: flex;
        margin-bottom: 2px;
    }

    .contact-value {
        display: flex;
    }
}
