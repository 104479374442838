.game-detail-modal .icon {
    font-size: 24px;
}

.game-detail-modal i.icon-Close {
    float: right;
}

.game-detail-modal .ui.grid.game-detail-grid {
    margin-top: 10px;
}

.game-detail-modal .game-title {
    margin-top: 14px !important;
}

.game-detail-modal .game-info-inner-wrapper,
.game-detail-modal .game-info-wrapper {
    padding: 5px;
}

.game-detail-modal .game-info-wrapper .info-wrapper .ticket-price {
    padding-left: 20px;
}

.game-detail-modal .game-info-inner-wrapper {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
}

.retail-payout-inner-wrapper {
    padding: 5px 14px 14px 5px !important;
}

.game-detail-modal .game-info-inner-wrapper,
.game-detail-modal .price-payout-wrapper,
.game-detail-modal .game-info-wrapper {
    margin-bottom: 15px;
}

.game-detail-modal .game-info-inner-wrapper > div.info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.game-detail-modal .add-item-action-wrapper > div,
.game-detail-modal .game-info-wrapper > div {
    display: inline-block;
}

.game-detail-modal .game-info-wrapper.commissions-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.game-detail-modal .game-info-inner-wrapper,
.game-detail-modal .game-info-wrapper.commissions-wrapper {
    width: 13em;
}

.game-detail-modal .game-info-inner-wrapper,
.game-detail-modal .game-info-wrapper.secondary-info,
.game-detail-modal .game-info-wrapper.commissions-wrapper {
    background: #ecebef;
    border-radius: 5px;
}

.game-detail-modal .game-info-wrapper > .info-wrapper.start-date {
    margin-right: 30px;
    width: 94px;
}

.game-detail-modal .game-info-wrapper .info-wrapper {
    padding: 5px;
}

.game-detail-modal .game-info-wrapper .info-wrapper:first-child {
    padding-left: 0;
}

.game-detail-modal .image-wrapper {
    width: 55px;
    background: white;
    display: inline-flex;
    justify-content: center;
    border-radius: 30px;
    margin: 5px;
}

.game-detail-modal .image-wrapper > .ui.image {
    padding: 7px;
}

.game-detail-modal .commission-amount-wrapper {
    padding: 14px;
}

.game-detail-modal .commission-amount-wrapper .commission-amount {
    text-align: center;
}

.game-detail-modal .ui.button.add-to-cart-btn {
    display: flex;
    align-items: center;
    width: 159px;
}

.game-detail-modal .ui.button.update-cart-btn {
    margin-left: 7px;
}

.in-cart-wrapper {
    display: inline-flex;
    align-items: center;
    padding-top: 6px;
}

.in-cart-wrapper .ui.yellow.label,
.ui.yellow.labels .label {
    color: #000000 !important;
}
.suggested-color {
    background: #99d9ea !important;
    font-width: bold;
}

.static-close-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 22px;
    cursor: pointer;
}

@media only screen and (min-width: 992px) {
    .ui.modal.game-detail-modal {
        width: 700px;
    }
}
