.calendar {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.calendar-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
}
.calendar-title-text {
    flex: 1;
    text-align: center;
    font-size: 14px;
}

.title-arrow {
    flex: 0.2;
    text-align: center;
    font-size: 18px;
}

.calendar-head {
    padding: 5px;
    text-align: center;
}

.calendar-content {
    flex: 1;
    align-items: center;
    padding-top: 9px;
    width: 100%;
    height: 100%;
}

.calendar-content > table {
    height: 90%;
}
.calendar-item {
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 0;
    text-align: center;
}

.calendar-item.disabled {
    cursor: default;
    opacity: 0.25;
}

.day-by-week > .calendar-content > table .calendar-title-text {
    font-size: 10px;
    color: #0c0033;
    line-height: 16px;
}

.today {
    color: #4786ce;
    font-weight: 700;
}

.today div {
    color: #4786ce !important;
    font-weight: 700;
    opacity: 1;
}

.selected-date {
    color: #ffffff !important;
    background-color: #337ab7;
    opacity: 1;
    border-radius: 50%;
}

.selected-date div {
    color: #ffffff !important;
    background-color: #337ab7;
    opacity: 1;
}

.disabled-calendar-item {
    opacity: 0.5;
    cursor: auto;
}

/* DayByWeek */

.day-by-week table {
    width: 100%;
}

.day-by-week .calendar-content {
    background-color: #ffffff;
}

.day-by-week .calendar-item.selected-date {
    border-radius: 3px;
}

.day-by-week .calendar-item {
    text-align: center;
}

.day-by-week .calendar-item.selected-date div {
    color: #ffffff;
}

.day-by-week:not(.read-only) .calendar-item:hover:not(.disabled-calendar-item) {
    border-radius: 3px;
    background-color: #337ab7;
    opacity: 0.7;
}

.day-by-week:not(.read-only)
    .calendar-item:hover:not(.disabled-calendar-item)
    div {
    color: #ffffff !important;
}

.day-by-week .disabled-calendar-item:hover {
    cursor: not-allowed;
}

.day-by-week.read-only .calendar-item:hover {
    cursor: default;
}

.day-by-week .date-subtext {
    color: #0c0033 !important;
    font-weight: 700;
    opacity: 1;
}

.scheduled-summary-wrapper {
    margin-top: 10px;
}

/* WeekSelector */
.week-selector .title-arrow,
.week-selector .week-selected {
    text-align: center;
}

@media screen and (max-width: 769px) {
    .week-selector .week-selected {
        font-size: 11px;
    }
    .date-subtext {
        font-size: 9px !important;
    }
}

@media screen and (max-width: 991px) {
    .day-by-week.read-only .calendar-item {
        padding: 2px 8px;
    }
}
