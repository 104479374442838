.pill {
    display: inline-flex;
    margin: 0 0 5px 0;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    background-color: #4786ce;
}

.rnp-control-header {
    padding: 10px 14px 12px;
    border-bottom: 1px solid rgba(12, 0, 51, 0.2);
}

.ui.popup.roles-filter-popup {
    padding: 0;
}

.ui.popup.roles-filter-popup .header {
    font-size: 14px;
    padding: 5px;
    text-align: center;
    opacity: 0.6;
}

.ui.vertical.menu.roles-filter-menu .item {
    padding: 8px 12px 8px 4px;
}

.ui.vertical.menu.roles-filter-menu {
    box-shadow: none;
}
.ui.menu.roles-filter-menu {
    border: none;
}

table.roles-permissions-table thead tr th:nth-child(1) {
    width: 425px;
}
table.roles-permissions-table thead tr th:not(:nth-child(1)) span {
    /*cursor: pointer;*/
}

table.roles-permissions-table thead tr th:not(:nth-child(1)) span:hover {
    /*cursor: pointer;*/
    /*text-decoration: underline;*/
}
.search-section {
    position: relative;
}

.edit-roles-section {
    position: absolute;
    right: 50px;
    top: 40px;
}

table.roles-permissions-table
    thead
    tr
    th:not(:nth-child(1))
    span:hover
    > span[class*='icon-Edit'] {
    visibility: visible;
}

table.roles-permissions-table
    thead
    tr
    th:not(:nth-child(1))
    span[class*='icon-Edit'] {
    visibility: hidden;
}

table.roles-permissions-table thead tr th:not(:nth-child(1)) {
    text-align: center;
}

table.roles-permissions-table tbody {
    overflow: auto;
    height: 60vh;
}

table.roles-permissions-table tbody tr td span[class*='icon'] {
    font-size: 20px;
}

table.roles-permissions-table tbody tr td:not(:nth-child(1)) {
    text-align: center;
}

.ui.table.roles-permissions-table {
    border: none;
    margin: 0;
}

.collapsed {
    visibility: collapse;
}

.table-body-div {
    height: 60vh;
    overflow: auto;
}
table.roles-permissions-table tbody tr.permission-group,
table.roles-permissions-table tbody tr td:nth-child(1) {
    background-color: rgba(12, 0, 51, 0.05);
}

table.roles-permissions-table tbody tr:not(.permission-group) td:nth-child(1) {
    padding-left: 25px !important;
}

table.roles-permissions-table tbody tr.permission-group {
    font-weight: bold;
}

.ui.checkbox input:checked ~ label:before,
.ui.checkbox input:not([type='radio']):indeterminate ~ label:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ label:before {
    background-color: #4786ce !important;
}

.ui.checkbox input:checked ~ label:after,
.ui.checkbox input:not([type='radio']):indeterminate ~ label:after,
.ui.checkbox input:checked:focus ~ label:after,
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ label:after {
    color: #fff !important;
}

.ui.checkbox.checked label:before {
    border-color: #4786ce !important;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
    border-color: #0c0033 !important;
}

.roles-filter-section {
    position: absolute;
    right: 0;
    margin-top: 18px;
}
