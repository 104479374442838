.main-form,
.p-form {
    width: 60%;
    text-align: left;
}

.p-form {
    margin-bottom: 1em;
}

.main-form > form,
form.p-form {
    background-color: #ffffff;
    padding: 0;
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
}

.p-form .form-footer {
    border-top: 2px solid #d4d4d5;
}

.sfaform-padding {
    padding: 20px;
}

.form-footer .ui.button {
    font-weight: normal;
}

.form-footer button.transparent-btn {
    line-height: 1em !important;
}

.ui.button.transparent-btn.delete-btn {
    border-right: 1px solid rgba(12, 0, 51, 0.5) !important;
    border-radius: 0 !important;
    color: #9390a7;
}

.form-footer div.right button {
    float: right;
}

.form-footer {
    padding: 20px !important;
    background: rgba(12, 0, 51, 0.05);
}

.word-wrap-with-word-break {
    word-break: break-word;
}

.ui.multiple.dropdown > .ui.label,
.ui.multiple.dropdown > .ui.active.label {
    background-color: #4786ce;
    color: white;
    border: none !important;
    border-radius: 10px;
}

.ui.multiple.dropdown > .label:hover {
    background-color: #87bbf7;
}

ul.p-list {
    width: 100%;
    margin: 0;
    padding: 0;
}

ul.p-list > li {
    display: inline-block;
    border: #e3e3e3 1px solid;
    border-radius: 25px;
    padding: 0.25em 0.75em;
    margin: 0 0.25em 0.5em 0;
    color: #0c0033;
    background-color: #fcfcfc;
}

.ui.form .field .disabled {
    opacity: 1;
}

.ui.form .field .disabled > input {
    background-color: rgba(12, 0, 51, 0.1);
    color: rgba(12, 0, 51, 0.25);
    opacity: 1;
    border-radius: 3px;
    border: 0;
}

.ui.form .field .ui.input,
.ui.form .fields .field .ui.input,
.ui.form .wide.field .ui.input {
    min-height: 55px;
}
.ui.form .selection.dropdown > input.search {
    line-height: 2em;
}

.ui.form .selection.dropdown > .dropdown.icon {
    line-height: 2.214286em;
}

.ui.form .field > .selection.dropdown {
    min-height: 55px;
    line-height: 2em;
}

.ui.form .field > .selection.dropdown > .menu:hover::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

.ui.form .field > .selection.dropdown > .menu:hover::-webkit-scrollbar-thumb {
    background: #cacaca !important;
}

.ui.form .field > .selection.dropdown > .menu:hover::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #e3e3e3 !important;
}

button.ui.button.deletebtn {
    color: #9390a7;
}

.time-picker-group,
.time-picker-group .ui.icon.button,
.time-picker-group input {
    width: 45px;
}

.time-picker-group input {
    font-size: 16px;
    text-align: center;
    height: 30px;
    border: none;
}

.time-picker-controls {
    display: inline-flex;
    padding-top: 30px;
}

.time-picker-group-hour {
    margin: 0;
}

.time-picker-group-minute {
    margin: 0;
}

.time-picker-separator {
    margin: auto 0;
    text-align: center;
}

.time-picker-group .ui.icon.button {
    height: 30px;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

.time-picker-group .ui.icon.button:hover,
.time-picker-group .ui.icon.button:focus {
    background-color: #e0e1e2;
}

.time-picker-group .ui.icon.button > .icon {
    font-size: 15px;
}

.time-picker-header .ui.small.header {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 6px;
    margin: 0;
}

.time-picker-header .icon {
    position: absolute;
    margin: 0;
    padding: 0;
    right: 8px;
    top: 8px;
}

.time-picker-header .icon:hover {
    cursor: pointer;
}

.time-picker-header .ui.divider {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 30px;
    left: 10px;
    right: 10px;
}

.p-label-calendar {
    height: 60px;
    width: 166px;
    background-color: #ffffff;
    color: rgba(12, 0, 51, 1) !important;
    margin: auto;
    font-size: 14px !important;
    font-weight: 700 !important;
    padding: auto;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
}
.p-label-calendar:hover {
    background: rgba(12, 0, 51, 0.1) !important;
    box-shadow: none;
}

.p-label-calendar:active {
    background: rgba(71, 134, 206, 0.25) !important;
    box-shadow: none;
}

.p-label-calendar-icon {
    height: 24px;
    width: 24px;
    color: rgba(12, 0, 51, 0.5);
    padding: 0 30px;
}

.p-display {
    font-size: 16px;
}

.field > .ui.radio.checkbox {
    opacity: unset;
}

.p-button.ui.button.neutral,
.p-button.ui.button.neutral:focus {
    background-color: rgb(255, 255, 255);
    color: rgb(134, 128, 154);
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
}

.p-button.ui.button.neutral:hover,
.p-button.ui.button.neutral:active {
    background-color: rgb(242, 242, 242);
}

.p-button.ui.button.positive,
.p-button.ui.button.positive:focus {
    background-color: rgb(71, 134, 206);
    color: rgb(255, 255, 255);
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
}

.p-button.ui.button.positive:hover,
.p-button.ui.button.positive:active {
    background-color: rgb(48, 110, 181);
}

.p-button.ui.button.important,
.p-button.ui.button.important:focus {
    background-color: rgb(255, 0, 72);
    color: rgb(255, 255, 255);
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
}

.p-button.ui.button.important:hover,
.p-button.ui.button.important:active {
    background-color: rgb(179, 0, 50);
}

.p-button.ui.button.neutral:focus,
.p-button.ui.button.positive:focus,
.p-button.ui.button.important:focus {
    outline: 2px solid rgb(71, 134, 206);
}

.p-display-image {
    height: 100px;
    width: 200px;
}

.p-display-image > img {
    max-width: 100%;
    max-height: 100%;
}
