.cart-drop-down-menu .cart-menu-item-wrapper {
    min-width: 300px;
    width: 420px;
    max-height: 85vh;
    overflow: auto;
}
.ui.dropdown .menu.cart-menu > .item.cart-menu-item-wrapper {
    padding: 0 !important;
}
.cart-drop-down-menu .cart-menu-item-wrapper .cart-header {
    background: #f2f4f8;
}

.cart-drop-down-menu .cart-menu-item-wrapper .game-info-wrapper {
    background: #e8f1fc;
}

.cart-drop-down-menu .cart-menu-item-wrapper i.icon {
    font-size: 1rem;
}

@media screen and (max-width: 500px) {
    .cart-drop-down-menu .cart-menu-item-wrapper {
        width: 93vw;
    }
}
