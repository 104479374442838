.login-form-controls {
    max-width: 370px;
    padding: 16px;
    margin: 0 15px;
    border-radius: 4px;
}

.login-form-controls .login-header {
    padding-top: 8px;
    margin-bottom: 16px;
    text-align: center;
    text-transform: uppercase;
}

.login-form-controls .login-logo-wrapper {
    height: 110px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form-controls .login-logo-wrapper > img {
    max-width: 300px;
}

.login-form-controls .ui.input {
    margin: 8px 0;
}

.login-form-controls .forgot-password-wrapper {
    text-align: right;
}

.login-form-controls > * {
    width: 100%;
}

.login-form-controls input {
    text-align: center !important;
}

.login-form-controls input::placeholder {
    color: black !important;
}

.login-form-controls button.ui.button {
    margin: 24px 0 16px 0;
}

.login-notification .ui.message > .close.icon {
    color: black;
}

.forgot-password-wrapper .blue-text {
    color: #1258a9 !important;
    filter: contrast(1);
}
