.main-form {
    width: 60%;
    text-align: left;
}

.main-form > form {
    background-color: #ffffff;
    padding: 0;
    box-shadow: 0 0 30px 0 rgba(72, 130, 176, 0.4);
    border-radius: 3px;
}

.sfaform-padding {
    padding: 20px;
}

.form-footer button.transparent-btn {
    line-height: 1em !important;
}

.delete-btn {
    border-right: 1px solid rgba(12, 0, 51, 0.5) !important;
    border-radius: 0 !important;
}
.preview-btn {
    border-right: 1px solid rgba(12, 0, 51, 0.5) !important;
    border-radius: 0 !important;
}

.reset-btn {
    border-right: 1px solid rgba(12, 0, 51, 0.5) !important;
    border-radius: 0 !important;
}

.word-wrap-with-word-break {
    word-break: break-word;
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) and (max-width: 1320px) {
    .main-form {
        width: 95%;
    }
}

/* tablet */
@media only screen and (max-width: 991px) {
    .main-form {
        width: 100%;
    }
    .right-extra {
        display: none !important;
    }
}

/* mobile */
@media only screen and (max-width: 767px) {
    .main-form > form {
        padding: 0;
    }

    .delete-btn {
        border-right: 0 !important;
        border-top: 1px solid rgba(12, 0, 51, 0.5) !important;
    }

    .reset-btn {
        border-right: 0 !important;
        border-top: 1px solid rgba(12, 0, 51, 0.5) !important;
    }
}
