.settings-content {
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(72, 130, 176, 0.4);
    margin: 15px;
    padding: 20px;
}

.form-padding {
    padding: 10px;
}

.ui.header.info-header {
    font-size: 14px;
    color: #0c0033;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 15px !important;
    font-weight: bold;
    border-bottom: 1px solid #dfdfdf;
}

.settings-row {
    margin-bottom: 2.5em;
}

.settings-row-header > label {
    font-weight: 700 !important;
}

.settings-row-header > .ui.tiny.basic.icon.button.transparent-btn {
    height: 25px;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin-left: 10px;
}

.settings-row-content {
    padding-left: 1em;
}

.settings-row-content > .inline.field {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
}

.settings-row-content > .inline.field > .field {
    flex: 0.9;
}

.Select-control {
    width: 100%;
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
    .ui.form.vertical-form.settings-content {
        width: 60%;
    }
}

/* tablet */
@media only screen and (max-width: 991px) {
    .ui.form.vertical-form.settings-content {
        width: 100%;
    }
}

/* mobile */
@media only screen and (max-width: 767px) {
    .ui.form.vertical-form.settings-content > form {
        padding: 0;
    }
}
