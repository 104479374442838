.cognito-center {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-46%, -50%);
    background: white;
    border-radius: 13px;
}
.cognito-parent {
    height: 100vh;
}

.cognito-logo-image {
    max-width: 23rem !important;
    padding-bottom: 3rem;
    padding-top: 2rem;
}
.cognito-login-button {
    min-width: 24rem;
    font-size: 23px !important;
    font-weight: bold !important;
    padding: 22px !important;
}
.loading {
    padding: 17px 52px !important;
}
.login-container {
    padding: 46px 0 !important;
}
