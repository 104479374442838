.error-page-layout {
    margin: 100px;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-break: break-word;
}

.error-page-style1,
.error--page-style2 {
    text-align: center;
    word-break: normal;
    line-height: initial;
}

.error-page-style1 {
    font-size: 30px;
    color: #fdc63b;
    margin-top: 20px;
    margin-bottom: 20px;
}

.error-page-style2 {
    font-size: 20px;
    margin-bottom: 20px;
}

.error-page-decription {
    margin-bottom: 5px;
    word-break: break-word;
}
