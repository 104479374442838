.NotFound {
    padding-top: 100px;
    text-align: center;
}

.error-layout {
    margin: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-break: break-word;
}

.error-style1,
.error-style2 {
    text-align: center;
    word-break: normal;
    line-height: initial;
}

.error-style1 {
    font-size: 40px;
    color: #fdc63b;
    margin-top: 25px;
    margin-bottom: 30px;
}

.error-style2 {
    font-size: 30px;
    margin-bottom: 40px;
}

.error-decription {
    margin-bottom: 5px;
    word-break: break-word;
}

.error-msgLink {
    margin-bottom: 5px;
    cursor: pointer;
}

.error-link {
    margin-top: 5px;
    word-break: break-word;
}
