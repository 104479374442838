.AppContainer.offline .dashboard button.more-detail-link,
#hidden-imgs-wrapper {
    display: none;
}

.ui.stackable.grid.dashboard {
    margin-top: 15px;
}

.ui.stackable.equal.width.grid.dashboard > .stretched.row,
.ui.stackable.equal.width.grid.dashboard > .stretched.row > .column {
    padding: 5px !important;
    flex-direction: row;
}

.show-hide-btn.ui.button.transparent-btn {
    padding: 0;
    height: auto;
    color: rgba(0, 0, 0, 0.95);
    position: absolute;
    right: 0;
}

.ui.basic.segment.show-hide-wrapper {
    position: relative;
    margin: 0;
    padding: 1em 0;
}

.ui.grid > .stretched.row > .column {
    margin-bottom: 30px;
}

.widget-heading {
    margin-bottom: 20px;
}

.ui.grid > .stretched.row > .column {
    flex-direction: row;
}

.game-info {
    text-align: left;
}

.carousel .slide {
    background: transparent;
}

.carousel .slide img {
    width: auto;
}

@media only screen and (max-width: 991px) {
    .row-small {
        min-height: 180px;
    }
    .row-large {
        min-height: 300px;
    }
    .row-extra-large {
        min-height: 400px;
    }
}

@media only screen and (min-width: 992px) {
    .row-small {
        min-height: 250px;
    }
    .row-large {
        min-height: 300px;
        height: 300px;
    }
    .row-extra-large {
        min-height: 400px;
        height: 400px;
    }
}
