.ui.progress.zerolength > .bar {
    min-width: 0;
}

.ui.progress.sfa-min-length > .bar {
    min-width: 3.2em;
}

.ui.progress.zerolength > .bar > .progress,
.ui.progress.sfa-min-length > .bar > .progress {
    font-size: 10px;
}

.label-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
}

.label-content-left {
    justify-content: space-between;
    display: flex;
}

.label-content-right {
    align-self: flex-end;
    flex: 1;
    text-align: right;
    color: rgba(0, 0, 0, 0.8);
}

.task-completion-bar {
    width: 100%;
}

.ytd-yellow-bar {
    background-color: #dddddd !important;
}

.ytd-white-bar {
    background-color: #ffffff !important;
    margin-top: 0 !important;
}

.ytd-yellow-bar .bar {
    background-color: #ffae04 !important;
}

.ytd-trim {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.ytd-opacity {
    display: flex;
    opacity: 0.5;
    justify-content: space-between !important;
}

@media only screen and (max-width: 991px) {
    .ytd-commissions > div.row > div.column {
        flex-grow: 0.4 !important;
    }
}
