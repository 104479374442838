.widget {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.widget-title {
    margin-bottom: 10px;
    text-transform: uppercase;
}

.widget-content {
    flex: 1 1 auto;
    /* overflow-y: auto; */
    align-items: center;
    padding: 0 5px;
    height: 265px;
    max-height: 265px;
}
.widget-content .widget-container-height {
    max-height: 220px;
    height: 220px;
    overflow-y: auto;
}
.widget-container-height::-webkit-scrollbar {
    width: 6px;
}

.widget-content-horizontally-centered {
    text-align: center;
}

.widget-footer {
    margin-top: 10px;
    align-self: flex-end;
}

.widget-clickable-item {
    cursor: pointer;
}

.widget-content-vertically-centered {
    margin: auto; /* this makes sure that oversized content is aligned properly, see https://stackoverflow.com/questions/24538100/issue-when-centering-vertically-with-flexbox-when-heights-are-unknown */
    width: 100%;
}

.high-tier-widget-wrapper .widget-content {
    overflow-y: visible;
}

.no-content {
    display: flex;
}

@media screen and (max-width: 991px) {
    .widget-content {
        overflow-y: visible;
    }
}
