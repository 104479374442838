.table-container {
    width: 100%;
    overflow-y: auto;
    margin: 0 0 1em;
}

.table-container::-webkit-scrollbar {
    display: inline;
}

.table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fff;
    background-color: rgba(0, 0, 0, 0.3);
}

.table-container-outer {
    position: relative;
    margin: 5px;
}

table.ui.basic.table {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

/* enable absolute positioning */
.inner-addon {
    position: relative;
    margin-bottom: 20px;
    max-width: 256px;
    width: 100%;
}

/* style icon */
.inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}

/* align icon */
.left-addon .glyphicon {
    left: 0px;
}

.right-addon .glyphicon {
    right: 0px;
}

/* add padding  */
.left-addon input {
    padding-left: 30px;
}

.right-addon input {
    padding-right: 30px;
}

.left-addon input::placeholder {
    opacity: 0.25;
    font-family: 'Open Sans', serif;
    font-size: 16px;
    color: #0c0033;
}

.right-addon input::placeholder {
    opacity: 0.25;
    font-family: 'Open Sans', serif;
    font-size: 16px;
    color: #0c0033;
}

.paging-section {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.paging-text {
    opacity: 0.5;
    font-family: 'Open Sans', serif;
    font-size: 12px;
    color: #0c0033;
    line-height: 16px;
    align-self: center;
}

.paging-items {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.mobile-paging-items {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    flex: 1;
}

.search-section {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
}

.search-section > .ui.input {
    width: 250px;
}

.search-section > .ui.button {
    padding: 10px;
}

.search-section > .ui.button > span {
    font-size: 20px;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-left: 15px;
}

.pagination > .ui.button.white,
.ui.circular.button.white {
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.5) !important;
}

.pagination > span {
    width: 60%;
}

.paging-button {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ui.medium.basic.fluid.button.paging-button,
.ui.mini.basic.button.paging-button.ending {
    padding: 0 !important;
    margin: 0 5px;
}

.ui.medium.basic.fluid.disabled.button.paging-button,
.ui.mini.basic.button.paging-button.ending.disabled > span {
    opacity: 0.5 !important;
}

.ui.medium.basic.fluid.button.paging-button:not(.disabled),
.ui.mini.basic.button.paging-button.ending:not(.disabled) > span {
    color: #4786ce !important;
}

button.ui.mini.basic.button.disabled.paging-button.numbering.transparent-btn,
button.ui.mini.basic.button.disabled.paging-button.numbering.transparent-btn
    > span {
    opacity: 1 !important;
    color: #4786ce !important;
    margin: 0 !important;
}

button.ui.mini.basic.button.paging-button.numbering.transparent-btn:not(.disabled) {
    opacity: 0.5 !important;
    margin: 0 !important;
}

button.ui.mini.basic.button.paging-button.numbering.transparent-btn:not(.disabled):hover {
    border-radius: 10em;
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.5) !important;
}

.sfaPopup > .content {
    word-wrap: break-word;
    max-width: 300px;
}

.ui.popup.sfaPopup {
    z-index: 900; /*so popup doesnt appear over dimmer*/
}

.table-container thead tr th .caption-text.secondary-color.bold {
    opacity: 1 !important;
}

@media screen and (max-width: 991px) {
    .search-section > .ui.input {
        width: 80%;
    }

    .pagination {
        margin-top: 10px;
        padding: 5px 30px;
        justify-content: center;
    }

    .pagination > .ui.button.white,
    .ui.circular.button.white {
        box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.5) !important;
        height: 36px !important;
        width: 36px !important;
    }

    .paging-button {
        margin-top: 5px;
    }
}

.action-menu-icon {
    margin: 0 !important;
}

.action-menu .action-menu-item {
    padding: 5px;
    cursor: pointer;
}

.action-menu-item:hover {
    background: #d0deee !important;
}
