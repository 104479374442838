.services-versions-widget-content .table-container {
    margin-bottom: 0px;
    height: 235px;
}
.services-versions-widget-content .table-container table tbody {
    display: block !important;
    max-height: 174px;
    overflow: auto !important;
}
.services-versions-widget-content
    .table-container
    table
    tbody::-webkit-scrollbar {
    width: 6px !important;
}

.services-versions-widget-content thead,
.services-versions-widget-content tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.services-versions-widget-content tbody tr td {
    border-top: none !important;
}
.services-versions-widget-content tbody tr td span {
    font-size: 0.85em !important;
}

.footer-no-margin {
    margin-top: 0 !important;
}

.services-versions-dir-col {
    padding: 5px 0px !important;
}

.services-versions-right-col,
.services-versions-middle-col {
    padding: 2px 0px !important;
    padding-right: 2px !important;
}

.services-versions-right-col {
    padding-right: 5px !important;
    text-align: right !important;
}

.widget-heading {
    margin-bottom: 0 !important;
}

.widget-title-refresh-button {
    display: inline-block;
    padding-left: 10px;
    color: #4686ce;
    cursor: pointer;
}

.services-versions-widget-content .ui.table thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
}
