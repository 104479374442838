.cart-icon-wrapper .ui.floating.label {
    top: 0;
    left: 95%;
    transition: top 1s;
    color: #0c0033 !important;
}

.retailer-nav.fix-header .cart-icon-wrapper .ui.floating.label {
    top: 0;
}

@media only screen and (min-width: 768px) {
    .cart-icon-wrapper .ui.floating.label {
        left: 97.5%;
    }
}

@media only screen and (min-width: 992px) {
    .cart-icon-wrapper .ui.floating.label {
        left: 80%;
    }

    .retailer-nav.fix-header .cart-icon-wrapper .ui.floating.label {
        top: -0.25em;
    }
}
