.game-list .price-header-sticky {
    margin: 0 -10px;
    top: 0;
    z-index: 9001;
    position: absolute;
    background: white;
}

.game-list .price-header-sticky.fix-header {
    top: 75px;
}

.game-list .catalog-content {
    height: 100vh;
    overflow-x: scroll;
}

.game-list .sfa-page {
    overflow: hidden;
}
.game-list .game-nav {
    width: 100%;
}

@media (min-width: 1091px) and (max-width: 1399px) {
    .game-list .price-header-sticky {
        top: 0px !important;
    }
}
@media (min-width: 992px) and (max-width: 1090px) {
    .game-list .price-header-sticky {
        top: 0px !important;
    }
}
