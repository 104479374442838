.server-time-widget-content .table-container {
    margin-bottom: 0px;
    height: 235px;
}
.server-time-widget-content .table-container table tbody {
    max-height: 174px;
    overflow: auto !important;
    display: grid !important;
    grid-template-columns: auto auto;
}
.server-time-widget-content .table-container table tbody::-webkit-scrollbar {
    width: 6px !important;
}
.server-time-widget-content .table-container table tbody tr:nth-child(odd) {
    padding-right: 7px !important;
}
.server-time-widget-content .table-container table tbody tr:nth-child(odd) td {
    width: 65% !important;
}
.server-time-widget-content .table-container table tbody tr:nth-child(even) td {
    padding-left: 15px !important;
}
.server-time-widget-content
    .table-container
    table
    tbody
    tr:nth-child(odd):last-child {
    border-bottom: none !important;
}
.server-time-widget-content
    .table-container
    table
    tbody
    tr:nth-child(odd)
    .System-time-right-col {
    text-align: left !important;
}
.server-time-widget-content .table-container table tbody tr td {
    width: 100% !important;
    border-top: none !important;
}
.server-time-widget-content .table-container table tbody tr td span {
    font-size: 0.85em !important;
}
.server-time-widget-content .table-container table thead tr {
    display: block !important;
}
.server-time-widget-content .table-container table thead tr th {
    width: 100% !important;
}

.footer-no-margin {
    margin-top: 0 !important;
}

.System-time-dir-col {
    padding: 5px 0px !important;
}

.System-time-right-col {
    text-align: right !important;
    padding: 2px 0px !important;
    padding-right: 2px !important;
}

.ui.table thead tr .service-widget:last-child {
    text-align: right !important;
}
