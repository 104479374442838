.form-button {
    padding-top: 22px;
}

.form-toggle {
    padding-left: 20px;
}

.form-toggle-checkbox {
    padding-top: 8px;
}
