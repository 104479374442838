/* needed so elements will reach to the bottom of the page */
body,
html,
#root {
    min-height: 100% !important;
    height: auto !important;
}

body {
    margin: 0;
}

#root {
    text-align: center;
}

.Select .Select-input input[role='combobox'] {
    border: none !important;
}

/* remove default x from inputs */
.search-section > div > input::-ms-clear {
    display: none;
}

.sfa-input-control {
    min-height: '55px'; /* default minHeight for all iput controls */
}

textarea {
    resize: none !important;
}

.pushable > .pusher {
    overflow: auto !important;
}

.sfa-page .breadcrumb {
    margin: 14px 0;
    text-align: left;
}

.ui.form .Select-control {
    border-color: rgba(34, 36, 38, 0.15);
}

.clickable {
    cursor: pointer;
}

table thead th {
    padding: 4px 5px !important;
}

table thead th:first-child {
    padding: 4px 0 !important;
    padding-right: 5px !important;
}

.icon.light-green {
    color: #00ff00;
}

.icon.yellow {
    color: #ff0;
}
.icon.red {
    color: #ed1c24;
}
/* animations */
@keyframes fadeLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@-webkit-keyframes fadeRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes fadeRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@-webkit-keyframes fadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@-webkit-keyframes jumpUp {
    0% {
        -webkit-transform: scale(0.5) translate(70px, 70px);
        transform: scale(0.5) translate(70px, 70px);
    }

    80% {
        -webkit-transform: scale(1.1) translate(-10px, -10px);
        transform: scale(1.1) translate(-10px, -10px);
    }

    100% {
        -webkit-transform: scale(1) translate(0px);
        transform: scale(1) translate(0px);
    }
}

@keyframes jumpUp {
    0% {
        -webkit-transform: scale(0.5) translate(70px, 70px);
        transform: scale(0.5) translate(70px, 70px);
    }

    80% {
        -webkit-transform: scale(1.1) translate(-10px, -10px);
        transform: scale(1.1) translate(-10px, -10px);
    }

    100% {
        -webkit-transform: scale(1) translate(0px);
        transform: scale(1) translate(0px);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ui.popup {
    -webkit-animation-name: fadeUp;
    animation-name: fadeUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ui.dropdown > .menu,
.Select-menu-outer {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ui.vertical.sfa-menu.menu .item:not(.disabled) .nav-item,
.ui.vertical.sfa-menu.menu .item:not(.disabled) .nav-sub-item {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.ui.vertical.sfa-menu.menu .item:not(.disabled) .nav-item:hover {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.ui.vertical.sfa-menu.menu .item:not(.disabled) .nav-sub-item:hover {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

@media screen and (max-width: 1351px) {
    .min-desktop {
        display: none !important;
    }
}

@media screen and (min-width: 1352px) {
    .max-tablet {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .max-mobile {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .min-tablet {
        display: none !important;
    }
}
