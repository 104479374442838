a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
}

*:focus {
    outline: 0;
}

@media only screen and (max-width: 991px) {
    .sfa-page {
        margin-top: 0;
        padding-top: 132px;
        height: auto;
    }
}

@media only screen and (min-width: 992px) {
    .sfa-page {
        margin: 35px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.sfa-page .sfa-page {
    margin: 0;
}

.title-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
}

.title-section > div.ui.header.subheader-text {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 10px;
}

.title-section > .move-right {
    margin-left: auto;
}

.blue-icon {
    color: #4786ce !important;
}

.icon-left-margin {
    margin-left: 5px;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 0.75rem !important;
}
.p-4 {
    padding: 1rem !important;
}
.p-0 {
    padding: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}
.pr-1 {
    padding-right: 0.25rem !important;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pr-3 {
    padding-right: 0.75rem !important;
}
.pr-4 {
    padding-right: 1rem !important;
}

.pr-5 {
    padding-right: 1.25rem !important;
}
.pr-6 {
    padding-right: 1.5rem !important;
}
.pr-7 {
    padding-right: 1.75rem !important;
}
.pr-8 {
    padding-right: 2rem !important;
}
.pl-0 {
    padding-left: 0 !important;
}
.pl-1 {
    padding-left: 0.25rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.pl-3 {
    padding-left: 0.75rem !important;
}
.pl-4 {
    padding-left: 1rem !important;
}
.pl-5 {
    padding-left: 1.25rem !important;
}

.pl-6 {
    padding-left: 1.5rem !important;
}

.pl-7 {
    padding-left: 1.75rem !important;
}
.pl-8 {
    padding-left: 2rem !important;
}
.pt-1 {
    padding-top: 0.25rem !important;
}
.pt-3 {
    padding-top: 0.75rem !important;
}
.pt-4 {
    padding-top: 1rem !important;
}
.pt-5 {
    padding-top: 1.25rem !important;
}
.pt-6 {
    padding-top: 1.5rem !important;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.pe-1 {
    padding-right: 0.25rem !important;
}
.pe-3 {
    padding-right: 0.75rem !important;
}
.pe-4 {
    padding-right: 1rem !important;
}
.pe-2 {
    padding-right: 0.5rem !important;
}
.ps-1 {
    padding-left: 0.25rem !important;
}
.ps-3 {
    padding-left: 0.75rem !important;
}
.ps-4 {
    padding-left: 1rem !important;
}
.ps-2 {
    padding-left: 0.5rem !important;
}
.ps-0 {
    padding-left: 0rem !important;
}

.ms-1 {
    margin-left: 0.25rem !important;
}
.ms-3 {
    margin-left: 0.75rem !important;
}
.ms-4 {
    margin-left: 1rem !important;
}
.ms-2 {
    margin-left: 0.5rem !important;
}
.ms-0 {
    margin-left: 0rem !important;
}
.me-1 {
    margin-right: 0.25rem !important;
}
.me-3 {
    margin-right: 0.75rem !important;
}
.me-4 {
    margin-right: 1rem !important;
}
.me-2 {
    margin-right: 0.5rem !important;
}
.me-0 {
    margin-right: 0rem !important;
}
.pt-0 {
    padding-top: 0rem !important;
}
.d-flex {
    display: flex !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 0.75rem !important;
}
.m-0 {
    margin: 0 !important;
}
.m-1 {
    margin: 0.25rem !important;
}
.m-2 {
    margin: 0.5rem !important;
}
.m-3 {
    margin: 0.75rem !important;
}
.m-4 {
    margin: 1rem !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.float-left {
    float: left;
}

.float-right {
    float: right;
}
.display-table {
    display: table !important;
}
.display-table-cell {
    display: table-cell !important;
}
.display-inline-flex {
    display: inline-flex !important;
}
.width-fit-content {
    width: fit-content;
}
.vertical-align-middle {
    vertical-align: middle;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pb-1 {
    padding-bottom: 0.25rem !important;
}
.pb-3 {
    padding-bottom: 0.75rem !important;
}
.pb-4 {
    padding-bottom: 1rem !important;
}
.pb-2 {
    padding-bottom: 0.5rem !important;
}
.pb-5 {
    padding-bottom: 1.25rem !important;
}
.pb-6 {
    padding-bottom: 1.5rem !important;
}
.pb-7 {
    padding-bottom: 1.75rem !important;
}
.pb-8 {
    padding-bottom: 2rem !important;
}
.text-align-s {
    text-align: start !important;
}

.text-align-e {
    text-align: end !important;
}

.text-align-c {
    text-align: center !important;
}
.color-black {
    color: black !important;
}
.opacity-1 > label {
    opacity: 1 !important;
}
.w-100 {
    width: 100% !important;
}

.height-90 {
    height: 90vh !important;
}
.overflow-x-scroll {
    overflow-x: scroll !important;
}
.overflow-hidden {
    overflow: hidden;
}
.text-overflow-ellipsis {
    text-overflow: ellipsis;
}
.position-relative {
    position: relative;
}
.custom-modal-background {
    position: absolute;
    background: #000000c0;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2000;
}

.custom-modal-body {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}
.justify-content-center {
    justify-content: center;
}
.justify-content-space-between {
    justify-content: space-between;
}
.align-self-center {
    align-self: center;
}
.white-space-nowrap {
    white-space: nowrap;
}
.white-space-normal {
    white-space: normal;
}

.flex-direction-row {
    flex-direction: row !important;
}

.flex-direction-row-reverse {
    flex-direction: row-reverse !important;
}
.border-none {
    border: none !important;
}

.background-transparent {
    background: transparent !important;
}
.ui.floating.label {
    z-index: 1 !important;
}
.object-fit-contain {
    object-fit: contain;
}
.word-break-break-all {
    word-break: break-all;
}
