.getting-started-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.getting-started-info-wrapper {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.getting-started-info-wrapper .text-wrapper .header {
    font-size: 22px !important;
    color: #0072b0 !important;
    letter-spacing: 0.6;
    margin: 4px 0 6px 0;
}

.getting-started-info-wrapper .text-wrapper .text {
    font-size: 14px;
    letter-spacing: 0.38;
}

.getting-started-info-wrapper img {
    display: inline-block;
    width: 115px;
    margin-bottom: 4px;
}

@media (max-width: 991px) {
    .getting-started-info-wrapper {
        max-width: 70%;
    }
}

@media (max-width: 320px) {
    .getting-started-info-wrapper .text-wrapper .header {
        font-size: 18px !important;
        letter-spacing: 0.5;
        margin: 4px 0 2px 0;
    }

    .getting-started-info-wrapper img {
        width: 50px;
    }
}
