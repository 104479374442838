.game-list .section-data-wrapper {
    padding-top: 85px;
    margin-top: -85px;
}

.retailer-view .section-data-wrapper {
    padding-top: 140px;
    margin-top: -180px;
}

.count-info {
    position: absolute;
    right: 5px;
    top: -12px;
    z-index: 99;
}
.clear-info {
    right: 21px;
    top: 6px;
    z-index: 99;

    padding: 4px;
    cursor: pointer;
}
.clear-info-all {
    position: absolute;
    top: -3px;
    right: -11.5px;
}
.clear-info-theme {
    position: absolute;
    top: 6px;
    right: 8px;
}

.clear-info-game-name {
    position: absolute;
    right: 12px;
    top: 5px;
}
.clear-info-white {
    color: #fff !important;
    padding-left: 0.5rem;
}
.clear-info-black {
    color: #000 !important;
}
.clear-info-border {
    border: 1px solid black;
    background: black;
    position: absolute;
    top: 0;
    right: -10px;
    border-radius: 10px;
}
.clear-info-cancel {
    font-size: 0.75em !important;
    padding-left: 0.15rem;
}
.filter-dropdown {
    background: #4786ce !important;
    color: #ffffff !important;
}

.filter-dropdown > .default.text,
.filter-dropdown > input.search,
.filter-dropdown > input.search:focus + .text {
    color: #ffffff !important;
}

.ui.selection.dropdown.filter-dropdown > .menu > .item {
    padding: 0 !important;
}

.game-nav .div-list-grid-option {
    border-radius: 2px;
    padding: 5px;
    border: 1px solid grey;
}
.game-nav .div-list-grid-option div {
    padding-left: 3px;
}
.game-nav .div-list-grid-option div:first-child {
    border-right: 1px solid grey;
}
.game-nav .div-filter-option {
    flex-direction: column;
    display: flex !important;

    z-index: 1;
    background: #fff;

    border-radius: 8px;
    display: none;
}
.game-nav .div-filter-option.mobile-show {
    display: flex !important;
}
.game-nav .div-filter-option div .ui.input.float-right {
    width: 100%;
}
.game-nav .div-filter-option div:first-child {
    padding-bottom: 0.5rem;
}
.game-nav .static-ui.mobile-show {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #000000af;
    display: flex !important;
    top: 0;
    left: 0;
}

.catalog-content .game-card-section {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
@media screen and (min-width: 768px) {
    .game-nav .div-filter-option {
        flex-direction: row-reverse;
        display: flex !important;
        position: relative;
        padding: 0rem;
        border-radius: 0px;
        top: 0px;
        right: 0px;
        background: none;
    }
    .game-nav .div-filter-option div:first-child {
        padding-bottom: 0rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    .game-nav .static-ui.mobile-show {
        display: none !important;
    }
    .game-card-column {
        min-width: 373px;
    }
}
