.checkout-items-component .ui.table tr td,
.checkout-items-component .ui.table tr th {
    border-bottom: 0.5rem solid white;
    border-top: none !important;

    text-align: right;
}
.checkout-items-component .ui.table tfoot tr td.background-transparent {
    background: transparent !important;
}
.checkout-items-component .ui.table tfoot tr td:first-child div {
    padding-left: 40px;
}
.checkout-items-component .ui.table tr td div {
    flex-direction: column;
}
.checkout-items-component .ui.table tr td .adjust-margin {
    margin-right: -8px;
}
.checkout-items-component .ui.table .close-table-cell {
    width: 10px;
}
.checkout-items-component .ui.table tr th {
    line-height: 2px;
}
.checkout-items-component .ui.table tr td {
    background: #e8f1fc;
    padding: 0.6rem 1rem !important;
}
.checkout-items-component .ui.table tfoot tr td {
    background: #f2f4f8 !important;
    height: 4rem;
}
.checkout-items-component .ui.table tr td:first-child,
.checkout-items-component .ui.table tr td:nth-child(4) {
    border-radius: 3px;
}
.checkout-items-component .ui.table tr td:last-child {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.checkout-items-component .ui.table tr {
    padding: 10px;
}

.checkout-items-component .game-item-mobile {
    background: #e8f1fc;
    margin: 0.5rem !important;
    border-radius: 3px;
}
.checkout-items-component .mobile-total-wrapper {
    background: #f2f4f8 !important;
}
.checkout-items-component .remove-game-mobile-div .remove-game-wrapper {
    padding: 0;
}
@media screen and (max-width: 767px) {
    .checkout-items-component .ui.table tr td.ptb-4 {
        padding-top: 1rem !important;
        padding-bottom: 2rem !important;
    }
    .checkout-items-component .ui.table tr td.min-tablet {
        display: none !important;
    }
    .checkout-items-component .ui.table tfoot tr td:first-child div {
        padding-left: 0px;
    }
    .checkout-items-component
        .ui.table
        tr
        td.background-transparent.close-table-cell {
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(78%, -50%);
        background: transparent !important;
    }

    .checkout-items-component
        .ui.table
        tr
        td.background-transparent.close-table-cell
        .suggested-quantity-warning-wrapper {
        margin-left: -7px;
        padding-right: 4px;
    }

    .checkout-items-component .ui.table tr.width-95 {
        position: relative;
        width: 95% !important;
    }

    .checkout-items-component .ui.table tr.width-30 {
        display: block !important;
        width: 30% !important;
    }

    .checkout-items-component .ui.table tr td,
    .checkout-items-component .ui.table tr th {
        border-bottom: 0.5rem solid white;
        border-top: none !important;

        text-align: right;
    }

    .checkout-items-component .ui.table tr td div {
        flex-direction: row !important;
    }

    .checkout-items-component .ui.table .close-table-cell {
        width: 10px;
    }

    .checkout-items-component .ui.table tr th {
        line-height: 2px;
    }

    .checkout-items-component .ui.table tr td {
        background: #e8f1fc !important;
        padding: 0.6rem 1rem !important;
        align-items: center;
        display: flex !important;
        flex-direction: column;
    }

    .checkout-items-component .ui.table tfoot tr td {
        background: #f2f4f8;
        height: 2rem;
    }
    .checkout-items-component .ui.table tfoot tr td .m-pr-0 {
        padding-right: 0 !important;
    }
    .checkout-items-component .ui.table tr td:first-child,
    .checkout-items-component .ui.table tr td:nth-child(4) {
        border-radius: 3px;
    }
    .checkout-items-component .ui.table tr td:first-child {
        padding-top: 1rem !important;
        text-align: center !important;
        font-weight: normal !important;
    }
    .checkout-items-component .ui.table tr td:nth-child(4) {
        padding-bottom: 1rem !important;
    }
    .checkout-items-component .ui.table tfoot tr td:nth-child(4) {
        padding-bottom: 3rem !important;
    }
    .checkout-items-component .ui.table tfoot tr td:nth-child(1) {
        height: 2.5rem !important;
        text-align: left !important;
    }
    .checkout-items-component .ui.table tr td:last-child {
        padding-right: 0 !important;
        padding-left: 0 !important;
        padding-bottom: 1rem !important;
    }

    .checkout-items-component .ui.table tr {
        padding: 10px;
    }
}
