.mobile-menu .ui.button .hamburger-box,
.mobile-menu .ui.button .hamburger-inner,
.mobile-menu .ui.button .hamburger-inner:after,
.mobile-menu .ui.button .hamburger-inner:before {
    width: 24px;
}

.mobile-menu .ui.button,
.mobile-menu .ui.button:focus,
.mobile-menu .ui.vertical.menu .item.ui.dropdown:focus,
.mobile-menu .ui.vertical.menu .item.ui.dropdown:hover {
    background: transparent;
}

.mobile-menu .ui.vertical.menu {
    position: fixed;
    width: 160px;
    top: 54px;
    padding: 10px 15px 0;
    box-shadow: 0px 0px 6px 0px #719ab29c;
    border: none;
}

.fix-header .mobile-menu .ui.vertical.menu {
    top: 30px;
    box-shadow: 0px 0px 6px 0px #719ab29c;
}

.mobile-menu .ui.vertical.menu .item {
    text-align: left;
    padding: 12px 0px 12px 0px;
}

.mobile-menu .ui.vertical.menu .item.ui.dropdown {
    padding: 0;
}

.mobile-menu .ui.vertical.menu .item.ui.dropdown > div.text {
    display: none;
}

.mobile-menu .ui.vertical.menu .dropdown.item:not(.upward) .menu {
    top: -30%;
}

.mobile-menu .ui.vertical.menu .dropdown.item .menu {
    left: 113%;
}

.retailer-nav.fix-header .mobile-menu .ui.button .hamburger-inner,
.retailer-nav.fix-header .mobile-menu .ui.button .hamburger-inner:after,
.retailer-nav.fix-header .mobile-menu .ui.button .hamburger-inner:before {
    background-color: white;
}
