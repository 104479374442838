.closingGames-dir-col {
    padding: 2px 0px !important;
}

.closingGames-right-col {
    text-align: left !important;
    padding: 2px 0px !important;
    padding-right: 2px !important;
}

.ui.table thead tr .summary-widget {
    padding-top: -8px !important;
    padding-bottom: 0px !important;
    height: 80% !important;
}

.ui.table thead tr .summary-widget:last-child {
    text-align: left !important;
}
