.past-orders-content {
    background: white;
    padding: 20px;
    position: relative;
}

.past-orders-content #page-content {
    background: white;
    padding: 20px;
    position: relative;
}

@media screen and (max-width: 767px) {
    .past-orders-content {
        padding: 0px;
    }
}
