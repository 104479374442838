.order-history-page .ui.secondary.pointing.menu.order-history-menu {
    display: inline-flex;
    background: white;
    border: none;
}

.order-history-page .ui.secondary.pointing.menu.order-history-menu .item {
    font-weight: 700;
}

.order-history-page
    .ui.secondary.pointing.menu.order-history-menu
    .active.item {
    border-bottom: 2px solid #1258a9;
    color: #1258a9;
}
