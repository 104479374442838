.footer-no-margin {
    margin-top: 0 !important;
}

.planogram-dir-col {
    padding: 2px 0px !important;
}

.planogram-right-col {
    text-align: right !important;
    padding: 2px 0px !important;
    padding-right: 2px !important;
}

.ui.table thead tr .summary-widget {
    padding-top: -8px !important;
    padding-bottom: 0px !important;
    height: 80% !important;
}

.ui.table thead tr .summary-widget:last-child {
    text-align: right !important;
}

.planogram-title {
    font-size: 20px;
    padding-bottom: 5px !important;
}

.planogram-section {
    font-size: 14px;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}
