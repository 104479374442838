.order-detail-page .order-info-wrapper {
    background: white;
    max-width: 991px !important;
}

.order-detail-page .info .info-cell {
    display: inline-block;
    vertical-align: text-top;
}

.order-detail-page .order-info-wrapper .order-info {
    background: #eeedf1;
}

.order-detail-page .shipment-details .shipment,
.order-detail-page .info {
    padding: 14px;
}

.order-detail-page .shipment-details .shipment .info {
    padding: 0;
}

.order-detail-page .info .info-cell {
    margin-right: 14px;
}

.order-detail-page .order-info-wrapper .order-details {
    padding: 14px;
}

.order-detail-page .ui.basic.button {
    background: white !important;
}

.order-detail-page .actions {
    margin: 7px 0;
    padding-bottom: 7px;
    border-bottom: 2px solid rgba(12, 0, 51, 0.25);
}

.order-detail-page .actions > button:not(:first-child) {
    margin-left: 14px;
}

.order-detail-page .game-item {
    max-width: 909px;
    margin-bottom: 7px;
}
.order-detail-page .game-item.totals-summary {
    background: #f2f4f8 !important;
    padding: 0 14px;
}
.order-detail-page .game-item.totals-summary .game-info-wrapper {
    background: none;
}
.order-detail-page .game-item .game-info-wrapper,
.order-detail-page .game-item {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.order-detail-page .game-item .game-info-wrapper {
    width: 100%;
    background: #e8f1fc;
    padding: 14px;
}

.order-detail-page .game-info .line-number {
    display: inline-block;
    width: 30px;
    text-align: right;
    margin-right: 10px;
}

.order-detail-page .game-item .game-info-wrapper .game-name-ref {
    word-break: break-word;
    white-space: normal;
}

.order-detail-page .sample-checkbox-wrapper {
    margin: 7px 0 0 40px;
}

.order-detail-page .totals-summary > .game-info-wrapper {
    justify-content: flex-end;
}

.order-detail-page .totals-summary .sample-checkbox-wrapper {
    margin: 7px 0 0 0;
}

.order-detail-page .header-wrapper,
.order-detail-page .qty-cost-wrapper {
    display: flex;
    width: 7em;
    justify-content: space-between;
}

.order-detail-page .header-wrapper {
    width: 100%;
}

.order-detail-page .header-wrapper .ui.header,
.order-detail-page .header-wrapper {
    margin: 0;
    background: white;
}

.order-detail-page .game-info-wrapper .game-info .total-quantity {
    display: flex;
}
.order-detail-page .header-wrapper .qty-cost-wrapper,
.order-detail-page .game-info-wrapper .game-info .total-quantity .label {
    margin-right: 10px;
    min-width: 107px;
}
.order-edit-model {
    height: 100%;
    padding: 0 !important;
}
.order-edit-model .price-header-sticky {
    top: 0 !important;
    background: #fff;
}

.order-detail-page .change-quantity-action-wrapper .quantity {
    padding: 0 14px;
}
.order-detail-page .totals-wrapper.header-wrapper {
    max-width: 909px;
    padding: 14px 26px;
    justify-content: end;
}
.order-detail-page .totals-wrapper {
    max-width: 869px;
    padding: 14px 0px 14px 7px;
}
.order-detail-page .totals-wrapper .total-quantity {
    max-width: 909px;
    padding-right: 2rem;
    width: 17% !important;
    padding-right: 28px !important;
    min-width: 242px;
}
.order-detail-page .totals-wrapper .suggested-quantity-header {
    padding-right: 5rem;
}

.order-detail-page .totals-wrapper .total-quantity .value {
    width: 87px;
    text-align: center;
}
.edit-order-game-list .catalog-content {
    padding-top: 106px !important;
}
.edit-order-game-list .sfa-page {
    padding-top: 0 !important;
}
.order-detail-page-modal > .close {
    top: -28px !important;
    right: -31px !important;
    color: #fff !important;
}
.order-detail-page .game-total-wrapper {
    width: 26%;
}

.order-detail-page .totals-wrapper.footer-wrapper .total-quantity {
    width: 33% !important;
}

.order-detail-page
    .totals-wrapper
    .total-quantity
    .value.total-cost.bold.min-tablet {
    padding-left: 126px;
}
.order-detail-page .total-quantity .qty-cost-wrapper {
    padding: 0 14px;
}
.order-detail-page .info {
    position: relative;
}
.order-detail-page .info .static-options {
    position: absolute;
    top: 15px;
    right: 0;
}
.order-detail-page .info {
    text-align: left;
}
.order-detail-page .info .static-options .ui.dropdown {
    position: unset;
}
.order-detail-page .info .static-options .ui.dropdown .menu {
    position: absolute;
    top: 13px;
    right: 0;
    left: auto;
}
.order-detail-page .shipment-details .info .static-options {
    top: -21px;
}
.order-detail-page-modal {
    height: 67vh;
    top: -2vh;
}
.ui.modal.order-detail-page-modal .price-header-sticky {
    width: 100% !important;
}
.ui.modal.order-detail-page-modal > .actions.menu-items {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    background: #eeedf1;
}

.order-detail-page-modal {
    width: 96vw !important;
}
.edit-order-game-list .game-nav {
    margin-top: 80px;
}
@media screen and (max-width: 767px) {
    .order-detail-page .item.dropdown .ui.button {
        background: transparent;

        border-radius: 0;
        color: #1258a9;
    }
    .edit-order-game-list .game-nav {
        margin-top: 0px;
    }
    .order-detail-page .item.dropdown .menu > .item {
        padding: 0 !important;
        border-bottom: 1px solid #1258a9;
    }
    .order-detail-page .item.dropdown .menu > .item:last-child {
        border-bottom: 0px solid #1258a9;
    }
    .ui.modal.order-detail-page-modal > .order-edit-model.content {
        padding: 0 !important;
    }
    /*.order-detail-page-modal .order-edit-model.content {*/
    /*    margin-left: -1rem !important;*/
    /*}*/
}
@media screen and (max-width: 991px) {
    .order-detail-page .header-wrapper {
        left: 0;
        margin: auto;
    }
    .edit-order-game-list .game-nav {
        margin-top: 0px;
    }
    .order-detail-page-modal {
        position: fixed !important;
        top: 9vh;
        left: 2vw;
        width: 96vw !important;
        height: 70vh;
    }
    .order-detail-page-modal .edit-order-game-list {
        margin-bottom: 80px;
    }
    .order-detail-page-modal > .order-edit-model.content {
        padding: 0 !important;
    }
}
@media screen and (max-width: 1351px) {
    .mobile-full-width {
        width: 100%;
    }
    /*.order-detail-page .info-cell{*/
    /*    display: flex !important;*/
    /*    justify-content: space-between;*/
    /*}*/
}
