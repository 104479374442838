.notification-bar {
    position: fixed !important;
    top: 0;
    z-index: 9001;
    width: 100%;
    left: 0;
    right: 0;
    white-space: pre-line;
}

.word-wrap-with-word-break {
    word-break: break-word;
}

@media screen and (min-width: 992px) {
    .notification-bar {
        width: 50%;
        margin: auto;
    }
}
