.confirm-modal.ui.modal > .modal-header.header:not(.ui) {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    border-bottom: none;
}

.confirm-modal.ui.modal > .modal-content.content {
    text-align: center;
    background-color: rgb(249, 248, 250);
}

.confirm-modal.ui.modal > .modal-content.content .content-message {
    margin: 12px;
}

.confirm-modal.ui.modal > .actions {
    text-align: center;
    background-color: rgb(243, 242, 245);
    border-top: none;
}

.confirm-modal.ui.modal > .modal-actions.actions .neutral-action.ui.button {
    min-width: 130px;
    height: 40px;
    padding: 12px 24px;
    margin: 16px 12px;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: normal;
    box-shadow: 0 2px 4px rgba(12, 0, 51, 0.2);
}

.confirm-modal.ui.modal .loading-text,
.confirm-modal.ui.modal .processing-text {
    font-family: 'Open Sans', serif;
    font-size: 16px;
    font-weight: normal;
}
