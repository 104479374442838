.last-order-summary-widget-content .table-container {
    margin-bottom: 0px;
    height: 235px;
}
.last-order-summary-widget-content .table-container table tbody {
    display: block !important;
    max-height: 174px;
    overflow: auto !important;
}
.last-order-summary-widget-content
    .table-container
    table
    tbody::-webkit-scrollbar {
    width: 6px !important;
}
.last-order-summary-widget-content .table-container table tbody tr td {
    width: 100% !important;
}
.last-order-summary-widget-content .table-container table thead tr {
    display: block !important;
}
.last-order-summary-widget-content .table-container table thead tr th {
    width: 100% !important;
}

.footer-no-margin {
    margin-top: 0 !important;
}

.last-order-summary-dir-col {
    padding: 5px 0px !important;
}

.last-order-summary-right-col {
    text-align: right !important;
    padding: 2px 0px !important;
    padding-right: 2px !important;
}

.ui.table thead tr .summary-widget:last-child {
    text-align: right !important;
}
