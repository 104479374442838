/*TODO Move to own component*/

.pill-list-item-wrapper {
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

/*TODO Move to own component*/

.pill {
    box-shadow: 0px 3px 3px #bababa;
    border-radius: 25px;
    padding: 2px 10px;
    margin-right: 10px;
    border-radius: 25px;
    color: #0c0033;
    background-color: #fcfcfc;
}

.pill span a {
    color: #000000;
}

.pill-inactive {
    border: #e3e3e3 2px solid;
    border-radius: 25px;
    padding: 2px 5px;
    color: #e3e3e3;
    background-color: #9d9d9d;
    opacity: 0.5;
}

.image-card {
    flex-grow: 1;
    display: inline-block;
    background-color: #ffffff;
    z-index: 1;
    border-radius: 8px;
    cursor: pointer;
    color: #7f7a97;
    font-size: 1.2em;
    -webkit-box-shadow: 0px 0px 3px 1px #bababa;
    box-shadow: 0px 0px 3px 1px #bababa;
}

.ticket-image {
    height: 400px;
    border-radius: 8px;
    display: block;
    margin: auto;
    max-height: 100%;
}

.ticket-image-icon {
    font-size: 24px !important;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 4px 0 0 0;
    background-color: #ffffff;
    white-space: nowrap;
    color: #0c0033;
    box-shadow: -1px -1px 4px 0 rgba(0, 0, 0, 0.3);
}

.section-data {
    display: block;
    margin-bottom: 40px;
    margin-top: 20px;
}
.catalog-content.fix-header {
    padding-bottom: 650px;
    padding-top: 36px;
}

.catalog-content {
    padding-top: 106px;
}

.price-header {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 10px;
    font-weight: 700;
    border-bottom: 3px solid #dfdfdf;
    font-family: 'Open Sans', serif;
}

.price-header-sticky {
    top: 116px;
    padding-top: 15px;
    transition: background-color 1s, color 1s, top 0.7s;
    position: fixed;
    width: inherit !important;
    z-index: 1;
    background: #fff;
}

.price-header-sticky.fix-header {
    background-color: #ffffff;
    box-shadow: 0 12px 6px -7px #d8e1e7;
    -webkit-box-shadow: 0 12px 6px -6px #d8e1e7;
    color: white;
    top: 73px;
    z-index: 1;
}

.price-header-sticky + #page-container {
    transition: padding-top 0.5s;
}

.price-header-sticky.fix-header + #page-container {
    padding-top: 40px;
}

.price-header-sticky.fix-header .ui.menu.desktop-menu {
    height: 40px;
}

.price-header-sticky.fix-header .ui.menu.desktop-menu a.item {
    padding: 6px 15px 11px 15px;
}

.price-header-sticky.fix-header .ui.menu.desktop-menu a.item {
    color: white;
}

.price-header-sticky.fix-header .ui.menu.desktop-menu a.active.item {
    background-color: #0c0033;
    border-color: white;
}

.image-card .game-info {
    font-size: 0.8em;
    color: #0c0033;
    display: flex;
    font-family: 'Open Sans', serif;
    line-height: 20px;
    padding-top: 10px;
    height: 20px;
    white-space: nowrap;
    width: 100%;
}

.image-card .game-info .ticket-commission {
    width: 50%;
    text-align: right;
}

.image-card .game-info .ticket-per-deal {
    width: 50%;
}

.price-header > h1 {
    position: relative;
    font-size: 16px;
    z-index: 0.9;
    overflow: hidden;
    text-align: left;
}

.price-header > h1:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 100%;
    height: 1px;
    content: '\a0';
    background-color: #7f7a97;
    margin-left: 10px;
}

.price-header > h1:before {
    margin-left: -5%;
    text-align: right;
}

.game-nav {
    width: 95%;
    color: #ffffff;
    margin-left: -35px;
}

.game-nav ul {
    padding-left: 30px !important;
}

.game-nav-header {
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-left: 30px !important;
}

.icon-cart-size {
    font-size: 24px !important;
    display: inline-block !important;
    align-content: space-around !important;
}

/* semantic ui react doesn't have custom break points so here we are 
    since we need to have a fixed width for the card or we have to make the elements in the card responsive
*/

@media (min-width: 992px) and (max-width: 1090px) {
    .ui.grid.Game-card-section
        > [class*='four wide computer'].column.game-card-column {
        width: 50% !important;
    }
    .game-nav {
        margin-top: 80px;
    }
    .price-header-sticky {
        top: 154px !important;
    }
    .price-header-sticky.fix-header {
        top: 92px !important;
    }
}

@media (min-width: 1091px) and (max-width: 1399px) {
    .ui.grid.Game-card-section
        > [class*='four wide computer'].column.game-card-column {
        width: 33% !important;
    }
    .game-nav {
        margin-top: 80px;
    }
    .price-header-sticky {
        top: 156px !important;
    }
    .price-header-sticky.fix-header {
        top: 92px !important;
    }
}

@media (max-width: 991px) {
    .game-nav {
        margin-top: 80px;
        color: #ffffff;
        margin-left: 1px;
        margin-right: 1px;
        width: 100% !important;
    }
    .catalog-content {
        padding-top: 40px;
    }
    .price-header-sticky {
        width: inherit !important;
    }
    .price-header-sticky.fix-header {
        width: inherit !important;
        top: 95px !important;
    }
    .price-header > h1:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 100%;
        height: 1px;
        content: '\a0';
    }
    .price-header > h1:before {
        margin-left: -50%;
        text-align: right;
    }
    .game-nav-header {
        margin-left: 0 !important;
    }
    .game-nav ul {
        padding-left: 0 !important;
    }
    .catalog-content.fix-header {
        padding-bottom: 150px;
    }
    .catalog-content .ui.header.price-header {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 768px) {
    .price-header > h1:after {
        width: 100%;
    }

    .catalog-content .ui.header.price-header {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 1550px) {
    .ticket-image {
        height: 408px;
    }
}
