.image-card .item-in-cart-wrapper {
    display: inline-flex;
    align-items: center;
}

.image-card .ticket-image-icon.in-cart .ui.label {
    display: inline-table;
    color: #0c0033 !important;
}

.plus-icon-size {
    font-size: 20px !important;
    height: 14px;
    width: 14px;
    color: #0c0033;
}
.game-card-footer {
    padding-top: 15px;
    min-width: 300px;
}

.game-ref-name {
    padding-top: 6px;
    height: 20px;
    color: #0c0033;
    font-family: 'Open Sans', serif;
    font-size: 14px;
    line-height: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.image-container {
    width: 300px;
    height: 395px;
    position: relative;
}

.image-container.mobile {
    height: 0;
}
.pos-container.mobile {
    height: 0;
    background: transparent;
    width: auto;
}
.pos-container.mobile .game-name {
    display: none;
}
.image-container.mobile .ticket-image-icon,
.pos-container.mobile .ticket-image-icon {
    bottom: -36px;
    box-shadow: none;
}
.game-card-footer.mobile .game-ref-name {
    text-align: left;
}
@media (min-width: 1540px) {
    .image-container {
        width: 360px;
        height: 410px;
    }
    .pos-container {
        width: 360px !important;
    }
    .game-name {
        margin-left: 44px !important;
    }
}

.text-container {
    padding-top: 90px;
    color: #0c0033 !important;
    font-size: 18px !important;
}

.game-name {
    width: 247px;
    height: 300px;
    padding: 30px;
    margin-left: 15px;
    margin-top: 40px;
    border-radius: 7px;
    border: 3px solid white;
    border-radius: 25px;
}

.pos-container {
    width: 300px;
    height: 410px;
    position: relative;
    background-color: powderblue;
}
