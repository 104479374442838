.return-list-page #page-content {
    position: relative;
}

.bg-transparent > input {
    background-color: transparent !important;
}

.search-criterion-input {
    max-width: 555px;
    min-width: 300px;
}

.search-criterion-input-2 {
    max-width: 515px;
    min-width: 300px;
}

.search-input-date-range {
    max-width: 515px;
    min-width: 300px;
}

.search-input-date-range > div:first-child {
    flex: 1;
}

.close-button-text {
    font-size: 30px;
    cursor: pointer;
}

.data-column {
    height: 75vh;
}

.pack-ticket-count {
    padding: 5px;
}

.close-icon {
    margin-top: -21px;
    margin-right: 3px;
}

.retailer-info-text {
    text-align: right !important;
    font-size: 17px;
}

.return-reason-text {
    padding-left: 310px;
    font-size: 17px;
}

.header-wrapper {
    text-align: center;
}

.packing-content {
}

.view-packing-content {
    /*padding-left: 300px !important;*/
    /*padding-right: 140px !important;*/
    /*padding-bottom: 50px !important;*/
    /*width: 920px;*/
}

.view-return-text {
    padding-left: 10px;
    margin-right: 100px;
}

.image-content {
    margin-top: 20px;
}

.radio-content {
    margin: 12px;
}

.ui.basic.modal.create-return {
    color: rgba(12, 0, 51, 1);
}
.ui.basic.fullscreen.modal.create-return {
    position: absolute;
    overflow: scroll;
    height: 99vh;
}

.ui.basic.fullscreen.modal.create-return-mobile {
    height: 86vh;
}

.pending-return-item {
    margin: 5px 0;
}

.pending-return-item span[class*='icon-'] {
    font-size: 20px;
    vertical-align: text-bottom;
}

.pending-return-item span.book-serial {
    padding: 0 30px 0 5px;
    font-size: 16px;
}

.warning.container.book-serial {
    background-color: #ffc000;
}

.book-serial {
    width: 175px;
    margin-left: 15px;
}

.ticket-labels {
    text-align: left;
    margin-left: 40px;
}

.individual-ticket-margin,
.margin-horizontal-2 {
    margin-left: 10px;
}

.margin-horizontal-10 {
    margin: 10px 0;
}

.show-ticket-details-arrow {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
}

.clicked.show-ticket-details-arrow {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.ticket-details {
    transition: display 0.5s ease-in;
}

.pending-return-input,
.ui.form .field .ui.input.pending-return-input,
.ui.form .field > .selection.dropdown.pending-return-input {
    height: 400px;
    min-height: 40px;
    max-height: 40px;
}

.return-status-label > span {
    background: rgba(71, 134, 206, 1);
    border-radius: 12px;
    padding: 4px 8px;
    color: white;
    line-height: 16px;
    font-size: 12px;
    font-weight: bold;
}

.pr-grid {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-rows: auto;
    grid-column-gap: 2%;
}

.grid-column {
    min-height: 40vh;
}

.pr-grid-item-pending {
    grid-column: 2 / span 2;
}

.pending-return-items-list {
    overflow-y: auto;
}

.view-return-list.pending-return-items-list {
    min-height: 55vh;
    height: 55vh;
}

.invisible {
    visibility: hidden;
}

.all-items-scanned {
    color: rgba(0, 211, 167, 1);
    font-size: 60px;
}

.ui.button.floating.simple.dropdown {
    background: transparent;
    color: white;
}

.ui.basic.modal.view-return-modal {
    color: rgba(12, 0, 51, 1);
}
.ui.basic.fullscreen.modal.view-return-modal {
    position: absolute;
    overflow: scroll;
    height: 99vh;
}

.ui.button.view-return-options-dropdown {
    color: white;
    position: 'absolute';
}

.ui.button.returnType {
    background-color: white;
}

.icon-content {
    background-color: white !important;
    float: right;
}
.return-reason-label {
    font-weight: bold !important;
    margin-left: 5px;
}

.scan-item-text {
    margin-top: 15px;
}

.return-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.text-right {
    text-align: right;
}

.float-right {
    float: right;
}

.scroll-vertical {
    overflow-y: scroll;
}

.close-icon-group {
    margin-top: 0px;
}

.close-icon-group-item {
}

.ticket-number-space {
}

.ticket-total {
    margin-top: 0px;
}

.cancel-header-text {
    padding-bottom: 35px;
}

.cancel-modal-content {
    padding: 20px;
}

.cancel-modal-message {
    font-weight: bold;
}

.pending-return-item-pos-item-count {
    text-align: left;
    margin-left: 36px;
}

.subheader-font-size {
    font-size: 16px;
}

.scanned-or-verified-items-container {
    background-color: rgb(12, 0, 51);
}

.scanned-or-verified-items-container.translucent {
    opacity: 0.5;
}

.scanned-or-verified-items-container .subheader-text {
    color: rgb(255, 255, 255);
}

.scanned-or-verified-items-container .scanned-or-verified-items-list {
    background-color: rgba(255, 255, 255, 0.1);
}
.static-close-button {
    position: absolute;
    top: 0;
    right: 0;
}
.input-container input {
    background: #fff !important;
}

.view-return-info-text {
    text-align: right !important;
    font-size: 14px;
}

/* Title */
.ui.accordion .title,
.ui.accordion .accordion .title {
    cursor: pointer;
    font-weight: bold;
}

/* Content */
.ui.accordion .title ~ .content,
.ui.accordion .accordion .title ~ .content {
    display: none;
    font-weight: normal;
}

.return-detail-accordian > .content.active {
    display: block !important;
}

@media screen and (max-width: 767px) {
    .retailer-info-text {
        text-align: left !important;
    }
    .data-column {
        height: auto !important;
    }
    .view-return-list {
        min-height: auto !important;
        height: auto !important;
    }
    .view-button-padding {
        padding: 9px !important;
    }
}
@media screen and (max-width: 991px) {
    .search-criterion-input {
        width: 100% !important;
    }
    .search-criterion-input {
        width: 100% !important;
    }
    .search-input-date-range {
        width: 100% !important;
    }
    .input-container > form {
        display: flex;
        justify-content: center;
    }
}
