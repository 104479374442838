#branding {
    margin-bottom: 20px;
}

#branding > .logo-wrapper > .logo {
    display: block;
    margin: auto;
    height: 30px;
    width: 180px;
}

#branding > .image-wrapper > .image {
    display: block;
    margin: auto;
}

#branding > .logo-wrapper {
    margin: 20px;
}

#branding > .image-wrapper {
    margin: 10px 10px 10px -17px;
}

.tag-line {
    color: #ffffff;
}

.tag-line-wrapper {
    margin-top: 20px;
}
