.p-notification-modal.ui.modal > .header {
    display: inline-flex;
    width: 100%;
    padding: 8px;
}

.p-notification-modal.ui.modal > .header i,
.p-notification-modal.ui.modal > .header span {
    height: 28px;
    line-height: 28px;
}

.p-notification-modal.ui.modal > .header span {
    margin: 0 auto;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}

.p-notification-modal.ui.modal > .header span > i {
    margin-left: 8px;
}

.p-notification-modal.ui.modal > .content {
    text-align: center;
    font-size: 16px;
    max-height: calc(100vh - 340px) !important;
    overflow-y: auto;
}

.p-notification-modal {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.p-notification-modal.ui.modal > .actions {
    background-color: rgb(239, 239, 242);
}

.p-notification-modal.ui.modal > .actions > .button {
    min-width: 120px;
    height: 50px;
    color: rgb(133, 127, 153);
}

.p-notification-modal.ui.modal > .actions > .positive.button {
    background-color: rgb(71, 134, 206);
    color: rgb(255, 255, 255);
}

.p-notification-modal.ui.modal > .actions > .positive.button:hover {
    background-color: rgb(41, 104, 176);
}

.p-notification-modal.ui.modal > .actions > .negative.button {
    background-color: rgb(88, 163, 248);
    color: rgb(255, 255, 255);
}

.p-notification-modal.ui.modal > .actions > .negative.button:hover {
    background-color: rgb(58, 133, 218);
}

.p-notification-modal.ui.modal .content-header {
    text-align: center;
    word-break: break-word;
}

.p-notification-modal-header-success {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(0, 211, 167) !important;
}

.p-notification-modal-header-info {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(71, 134, 206) !important;
}

.p-notification-modal-header-warn {
    color: rgb(0, 0, 0) !important;
    background-color: rgb(255, 192, 0) !important;
}

.p-notification-modal-header-error {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(255, 0, 72) !important;
}
