.btn-link-text {
    opacity: 0.5;
    font-family: 'Open Sans', serif;
    font-size: 14px;
    color: #0c0033;
    letter-spacing: 0;
    line-height: 20px;
}

.data-section {
    flex: 0.9;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    padding: 20px;
    width: 100%;
    min-width: 100%;
}
