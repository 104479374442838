a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
}

.filter-page {
    width: 100%;
}

.filter-section {
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    height: 140px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.filterSection > div.ui.menu {
    flex-wrap: wrap;
}

.mobile-filter-section {
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    width: 100%;
    margin-bottom: 15px;
    padding: 5px;
}

.mobile-filter-section-no-show {
    position: relative;
    margin: 10px 0;
}

.badge-filters {
    position: absolute;
    top: -7px;
    left: 90px;
    font-size: 12px;
    font-weight: 500;
}

.mobile-filter-section-heading {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
    height: 25px;
    font-size: 16px;
}

.mobile-filter-heading-text {
    flex: 1;
    justify-content: flex-start;
    align-self: center;
}

.mobile-filter-heading-icon {
    font-size: 18px;
    align-self: center;
    opacity: 0.5;
    color: #0c0033;
}

.disabled-filterItem {
    color: #000000;
    cursor: default;
    font-size: 1rem;
}

.mobile-filter-list {
    margin-left: 20px;
    margin-right: 16px;
}

.filter-section-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.filter-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 20px;
}
.filter-page .max-tablet .ui.vertical.segment.filterSection {
    background: transparent;
    box-shadow: none !important;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 10;
    padding: 0;
}

.filter-page
    .max-tablet
    .ui.vertical.segment.filterSection.filterSectionActive {
    background: #fff;
    margin-right: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 4px 0px rgb(34 36 38 / 12%),
        0px 2px 10px 0px rgb(34 36 38 / 15%);
}

.filter-page .max-tablet .ui.vertical.segment.filterSection .item {
    padding: 0 10px;
}
.filter-page .max-tablet .ui.floating.label.badge {
    margin-left: -1.75rem !important;
    top: -1.25rem;
}
.filter-page
    .max-tablet
    .ui.vertical.segment.filterSection
    .filter-item
    .chevron.down.icon {
    position: absolute;
    right: 0;
    margin-right: 0.5rem !important;
}
.filter-page .max-tablet .filter-item {
    padding-right: 1rem;
    padding-left: 2rem;
}
.filter-page .max-tablet .ui.floating.label.badge.total-badge-count {
    margin-left: -1.5rem !important;
}
@media only screen and (max-width: 991px) {
    .filter-item {
        height: 40px;
    }
}

.filter-item > i,
.filter-item > span {
    align-self: center;
}

.filter-section-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    margin-top: 16px;
    margin-left: 5px;
    margin-bottom: 10px;
    max-width: 300px;
}

@media only screen and (max-width: 991px) {
    .filter-section-buttons > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }

    .filter-section-buttons > div > .ui.button.transparent-btn.clear-all {
        margin-top: 10px;
    }
}

.filter-btn {
    border-color: #58a3f8;
    background-color: #58a3f8;
    color: #ffffff;
}

.filter-btn-link {
    text-decoration: none;
    margin: 11px;
}

.searchable-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
}

.selection-actions {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.selection-actions > span {
    opacity: 0.25;
}

.selection-actions > .ui.button {
    height: 20px;
}

.selection-actions-all {
    margin-right: 15px;
}

.ui.button.selection-actions-clear {
    padding-right: 0;
}

.filter-a {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    border-radius: 3px;
    padding: 5px;
}

.filter-a-on {
    background-color: #58a3f8;
    color: #ffffff;
}

.filter-a-off {
    background-color: transparent;
    color: #0c0033;
}

.filter-text {
    font-size: 14px;
    text-align: left;
    width: 95%;
}

.filter-text-on {
    color: #ffffff;
}

.filter-text-off {
    color: #0c0033;
}

.filter-icon-on {
    font-weight: 700;
    justify-content: flex-end;
    color: #ffffff;
    font-size: 20px;
}

.filter-icon-off {
    font-weight: 700;
    justify-content: flex-end;
    color: #0c0033;
    font-size: 20px;
}

.badge-top {
    position: absolute;
    top: -10px;
    left: 75px;
    font-size: 12px;
    font-weight: 500;
}

.badge-on {
    background-color: #0c0033;
    color: #ffffff;
}

.badge-off {
    background-color: transparent;
    color: #0c0033;
}

.popover-container {
    position: absolute;
    width: 272px;
    min-width: 272px;
    height: 180px;
    padding: 1em;
    box-sizing: border-box;
    border: 1px solid #dadada;
    background: #ffffff;
    z-index: 1060;
    margin-top: 3px;
    border-radius: 3px;
}

.popover-container,
.popover-container:before {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.selected {
    background-color: #e4edf8;
}

/* Material Checkbox styles */
.md-checkbox {
    position: relative;
    margin: 2px 0;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.md-checkbox.double {
    min-height: 55px;
}

.md-checkbox label {
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.md-checkbox label > span {
    margin-left: 30px;
}

/* box outline around check mark */
.md-checkbox label:before,
.md-checkbox label:after {
    content: '';
    position: absolute;
    top: 10px;
}
.md-checkbox.double label:before,
.md-checkbox label:after {
    top: 18px;
}
.md-checkbox label:before {
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 2px;
    cursor: pointer;
    transition: background 0.3s;
}

/* check marks that are rendered */
.md-checkbox input[type='checkbox']:checked + label:before {
    background: #337ab7;
    border: none;
}
.md-checkbox input[type='checkbox']:checked + label:after {
    transform: rotate(-45deg);
    top: 16px;
    left: 9px;
    width: 16px;
    height: 6px;
    border: 2px solid #fff;
    border-top-style: none;
    border-right-style: none;
}

.md-checkbox.double input[type='checkbox']:checked + label:after {
    top: 25px;
}

/* actual DOM checkbox */
.md-checkbox input[type='checkbox'] {
    display: none;
}

.md-checkbox:not(.selected):hover {
    background-color: #f5f3f6;
}

/* RADIO button */
.md-radio {
    margin: 2px 0;
    height: 44px;
    display: flex;
}
.md-radio input[type='radio'] {
    display: none;
}
.md-radio input[type='radio']:checked + label:before {
    border-color: #337ab7;
    animation: ripple 0.2s linear forwards;
}
.md-radio input[type='radio']:checked + label:after {
    transform: scale(1);
}
.md-radio label {
    display: inline-flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
}

.md-radio label:before,
.md-radio label:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: all 0.3s ease;
    transition-property: transform, border-color;
    align-self: center;
}
.md-radio label:before {
    left: 3px;
    top: 30%;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.54);
}
.md-radio label:after {
    left: 8px;
    top: 40%;
    width: 10px;
    height: 10px;
    transform: scale(0);
    background: #337ab7;
}

.md-radio:not(.selected):hover {
    background-color: #f5f3f6;
}
