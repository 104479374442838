.form-footer div.right button {
    float: right;
}

.form-footer {
    padding: 20px !important;
    background: rgba(12, 0, 51, 0.05);
}

/* mobile */
@media only screen and (max-width: 767px) {
    .form-footer,
    .form-footer > div {
        padding: 0 !important;
    }

    .form-footer > div {
        border: none !important;
    }

    .form-footer button {
        width: 100%;
    }
}
