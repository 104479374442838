.number-incrementor .label {
    margin-bottom: 7px;
}

.number-incrementor .control-wrapper {
    display: flex;
}

.number-incrementor .control-wrapper .ui.input {
    width: 70px;
    margin-right: 7px;
}

.number-incrementor .control-wrapper .ui.button {
    margin: 0 3.5px;
    width: 40px;
    padding: 0;
}

.number-incrementor .control-wrapper .ui.button i.icon {
    margin: 0 !important;
}
