.reports-page {
    flex: 0.9;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    padding: 20px;
    width: 65%;
    min-width: 65%;
    min-height: 500px;
}

.reports-page > .search-section > .ui.input {
    width: 100%;
}

.ui.selection.list .list > .item,
.ui.selection.list > .item.group-name {
    margin: 15px 0 0;
}

.report-item {
    margin: 5px 0;
}

.reports-page .search-width {
    width: fit-content;
}

@media screen and (max-width: 991px) {
    .reports-page {
        width: 100%;
        min-width: 100%;
    }
}

.sales-report {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.task-completion-report {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.textContainerCss {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
