.invoice-detail-page .detail-wrapper {
    background: white;
    padding: 14px;
    max-width: 991px;
}

.invoice-detail-page .invoice-details {
    overflow: auto;
}

.invoice-detail-page .invoice-info .info .info-cell {
    display: inline-block;
    vertical-align: text-top;
}

.invoice-detail-page .detail-wrapper .invoice-info .info {
    margin-bottom: 14px;
}

.invoice-detail-page .detail-wrapper .invoice-info .info .info-cell {
    margin-right: 14px;
}

.invoice-detail-page .detail-wrapper .invoice-info .info .info-cell .cancelled {
    color: #ff2f29;
    font-weight: bold;
}

.invoice-detail-page .actions {
    margin: 7px 0;
    padding-bottom: 7px;
    border-bottom: 2px solid rgba(12, 0, 51, 0.25);
}

.invoice-detail-page .invoice-details .detail-item-row {
    min-width: 425px;
}

.invoice-detail-page .invoice-details .detail-item-row:not(:last-child) {
    border-bottom: 1px solid rgba(12, 0, 51, 0.1);
}

.invoice-detail-page .invoice-details .ui.grid.detail-items {
    margin: 0;
    overflow: auto;
}

.invoice-detail-page .invoice-details .ui.grid.detail-items::-webkit-scrollbar {
    display: initial;
}
