.search-input-container > form {
    display: flex;
}

.search-input-field {
    width: 50% !important;
    max-width: 200px !important;
    position: relative;
}

.square-button {
    font-size: large !important;
    height: auto !important;
}
