.custom-pop-up {
    position: relative;
}

.custom-pop-up .ui.bottom.right.popup.transition.visible {
    background: #0c0033 !important;

    top: 27px;
    right: 0px;
    color: white;
    border-radius: 6px;
    width: fit-content;
    min-width: 204px;
    white-space: normal;
}
.custom-pop-up .ui.bottom.right.popup.transition.visible:before {
    right: 0.6em;
}
.custom-pop-up .ui.bottom.right.popup:before {
    background: #0c0033 !important;
}
