.invoices-content {
    background: white;
    padding: 20px;
}

.invoices-content table th:nth-child(4),
.invoices-content table td:nth-child(4) {
    text-align: right !important;
}

.invoices-content table th:nth-child(4) > div > span {
    padding-right: 12px !important;
}

.invoices-content table th:nth-child(4).sorted > div > span,
.invoices-content table th:nth-child(4).sortable > div > span {
    padding-right: 0 !important;
}
