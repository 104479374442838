.Select-control,
.Select-placeholder,
.Select-value {
    padding: 0.67857143em 1em;
}

.Select-control {
    padding-left: 0;
    padding-right: 0;
}

.invalid > .Select-control {
    border-color: #ff0048 !important;
}
