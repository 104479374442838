.shipment-header .shipment,
.shipment-header .info {
    padding: 14px;
}

.shipment-header .shipment-details .shipment .info {
    padding: 0;
}
.shipment-header .info .info-cell {
    margin-right: 14px;
}
.shipment-header .info .info-cell {
    display: inline-block;
    vertical-align: text-top;
}

.shipment-header .info {
    text-align: left;
}
.shipment-header {
    position: relative;
}
.shipment-header .info .static-options {
    position: absolute;
    top: 15px;
    right: 0;
}
