.game-detail-modal .icon {
    font-size: 24px;
}

.game-detail-modal i.icon-Close {
    float: right;
}

.game-detail-modal .ui.grid.game-detail-grid {
    margin-top: 10px;
}

.game-detail-modal .game-title {
    margin-top: 14px !important;
}

.game-detail-modal .game-info-inner-wrapper {
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
}

.game-detail-modal .game-info-inner-wrapper > div.info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.game-detail-modal .add-item-action-wrapper > div,
.game-detail-modal .game-info-wrapper > div {
    display: inline-block;
}

.game-detail-modal .game-info-inner-wrapper,
.game-detail-modal .game-info-wrapper.secondary-info,
.game-detail-modal {
    background: #ecebef;
    border-radius: 5px;
}

.game-detail-modal .ui.button.add-to-cart-btn {
    display: flex;
    align-items: center;
}

.game-detail-modal .ui.button.update-cart-btn {
    margin-left: 7px;
}

.in-cart-wrapper {
    display: inline-flex;
    align-items: center;
    padding-top: 6px;
}

.in-cart-wrapper .ui.yellow.label,
.ui.yellow.labels .label {
    color: #000000 !important;
}

@media only screen and (min-width: 992px) {
    .ui.modal.game-detail-modal {
        width: 700px;
    }
}

.game-name-modal {
    width: 220px;
    height: 300px;
    padding: 30px;
    margin-left: 25px;
    margin-top: 40px;
    border-radius: 7px;
    border: 3px solid white;
    border-radius: 25px;
}

.pos-container-modal {
    width: 300px;
    height: 400px;
    position: relative;
    background-color: powderblue;
    border-radius: 7px;
}

.text-container-modal {
    padding-top: 80px;
    padding-left: 20px;
    color: #000000 !important;
    font-size: 18px !important;
}

.static-close-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 22px;
    cursor: pointer;
}
