.in-progress-order-card {
    margin-bottom: 24px;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.5);
}

.in-progress-order-card .order-info-wrapper,
.in-progress-order-card .summary-header {
    padding: 14px;
}

.in-progress-order-card .summary-header,
.in-progress-order-card .order-info-wrapper {
    text-align: left;
}

.in-progress-order-card .summary-header {
    background: #f2f2f2;
}

.in-progress-order-card .order-info-wrapper {
    background: white;
}

.in-progress-order-card .order-info-wrapper:not(:last-child) .order-info {
    margin-bottom: 5px;
    border-bottom: #eaeaea 1px solid;
}

.in-progress-order-card .order-info .info .info-cell,
.in-progress-order-card .summary-header .summary-header-section {
    display: inline-block;
    vertical-align: text-top;
}

.in-progress-order-card .summary-header .summary-header-section {
    padding-bottom: 14px;
    padding-right: 14px;
}

.in-progress-order-card .order-details-link {
    text-decoration: underline;
}

.in-progress-order-card .order-info-wrapper .order-info .info {
    margin-bottom: 14px;
}

.in-progress-order-card .order-info-wrapper .order-info .info .info-cell {
    margin-right: 14px;
}

.in-progress-order-card .actions > button:not(:first-child) {
    margin-left: 14px;
}

.in-progress-order-card .actions > .ui.basic.button {
    background: #fff !important;
    color: rgba(0, 0, 0, 0.8) !important;
}

.in-progress-order-card .actions > .ui.basic.button:hover {
    background: transparent none !important;
    color: rgba(0, 0, 0, 0.6) !important;
}
.in-progress-order-card .shipment-row {
    background: #fff;
}

.in-progress-order-card .shipment-detail-accordion > .content.active {
    display: block !important;
}
@media screen and (min-width: 768px) {
    .in-progress-order-card
        .summary-header
        .summary-header-section:not(:first-child) {
        border-left: 2px solid rgba(12, 0, 51, 0.25);
        padding-left: 14px;
    }

    .in-progress-order-card .summary-header .summary-header-section {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 767px) {
    .in-progress-order-card .item.dropdown .ui.button {
        background: transparent;

        border-radius: 0;
        color: #1258a9;
    }

    .in-progress-order-card .item.dropdown .menu > .item {
        padding: 0 !important;
        border-bottom: 1px solid #1258a9;
    }
    .in-progress-order-card .item.dropdown .menu > .item:last-child {
        border-bottom: 0px solid #1258a9;
    }
}
