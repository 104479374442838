.login-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 32px;
}

.login-wrapper .login-footer-wrapper,
.login-wrapper .login-footer-wrapper .ui.button {
    height: 44px;
}

.login-wrapper .login-footer-wrapper {
    position: fixed;
    width: 100%;
    bottom: 0;
    text-align: center;
}

.login-wrapper .login-footer-wrapper .ui.button {
    background-color: transparent;
    width: 108px;
    padding: 0;
    margin: 0 8px;
}

.login-wrapper .sfa-page {
    padding-top: 0;
}

.faq-wrapper {
    display: inline-block;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    padding: 20px;
    width: 992px;
    min-height: 500px;
}

.faq-title {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
}

.faq-content {
    text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .faq-wrapper {
        width: 768px;
    }
}

@media screen and (max-width: 767px) {
    .faq-wrapper {
        width: 100%;
    }
}
