.data-display-wrapper {
    margin: 15px;
}

.data-display-wrapper .nav-back,
.add-attachment-btn-wrapper {
    margin-bottom: 10px;
}

.data-display-wrapper .nav-back > a {
    cursor: pointer;
    color: #0c0033 !important;
}

.data-display-content {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(72, 130, 176, 0.4) 0px 0px 30px 0px;
    padding: 20px;
}

.center-header {
    text-align: center !important;
}
.doc-del-container h4 {
    margin-top: 7px;
}
