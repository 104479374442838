.retailer-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    transition: background-color 1s, color 1s;
    box-shadow: 0 12px 6px -6px #d8e1e7;
    z-index: 2;
    flex-direction: column;
}
.retailer-nav .inner-div {
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
}
.retailer-nav .retailer-info-mobile {
    background: #0c0033;
    width: 100%;
    padding: 1rem;
    color: white;
    font-size: 14px;
    font-weight: bold;
}
.retailer-nav .user-account-dropdown {
    z-index: 200;
}
.retailer-nav.fix-header {
    background-color: #0c0033;
    color: white;
    position: fixed;
    top: 0;
    width: inherit;
    z-index: 200;
}

.retailer-nav .app-logo-wrapper {
    padding: 8px;
}

.retailer-nav .ui.menu.desktop-menu {
    margin: 0;
    height: 80px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    transition: height 1s;
}

.retailer-nav .ui.menu.desktop-menu .dropdown {
    height: 100%;
}

.retailer-nav .ui.menu.desktop-menu .cart-item .dropdown.icon {
    height: auto;
}

.retailer-nav .ui.menu.desktop-menu .dropdown.item::before,
.retailer-nav .ui.menu.desktop-menu .dropdown.item {
    background: none;
}

.retailer-nav .ui.menu.desktop-menu a.item {
    padding: 26px 15px 30px 15px;
    transition: padding 1s;
}

.retailer-nav .ui.menu.desktop-menu a.item:hover {
    background-color: rgba(18, 88, 169, 0.2);
}

.retailer-nav .ui.menu.desktop-menu .item:first-child {
    border-radius: 0;
}

.retailer-nav .ui.menu.desktop-menu a.item.account-item,
.retailer-nav .ui.menu.desktop-menu a.item.cart-item {
    padding: 0 15px;
}

.retailer-nav .ui.menu.desktop-menu a.active.item {
    border-top: 4px #1258a9 solid;
    background-color: white;
    color: #1258a9;
    font-weight: 400;
    transition: background-color 1s, color 1s;
}

.retailer-nav i.icon {
    font-size: 24px;
}

.retailer-nav .app-logo-wrapper > img {
    max-width: 150px;
    max-height: 60px;
    transition: width 1s;
}

.mobile-icon-wrapper {
    width: 70px;
    z-index: 11;
}

.retailer-nav + #page-container {
    padding-top: 0;
    transition: padding-top 0.5s;
}

.retailer-nav .account-item .ui.dropdown .menu > .item.account-info {
    background: rgba(0, 0, 0, 0.05) !important;
}

.retailer-nav
    .account-item
    .ui.dropdown
    .menu
    > .item.background-hover-colour:hover {
    background: #d0deee !important;
}

.retailer-nav-info {
    font-weight: bold;
}

/* fixed header styles */

.retailer-nav.fix-header + #page-container {
    padding: 75px 0 50px;
}

.retailer-nav.fix-header .ui.menu.desktop-menu {
    height: 50px;
}

.retailer-nav.fix-header i.icon,
.retailer-nav.fix-header .ui.menu.desktop-menu a.item {
    color: white;
}

.retailer-nav.fix-header .ui.menu.desktop-menu a.item {
    padding: 6px 15px 11px 15px;
}

.retailer-nav.fix-header .ui.menu.desktop-menu a.item.account-item,
.retailer-nav.fix-header .ui.menu.desktop-menu a.item.cart-item {
    padding: 0 15px;
}

.retailer-nav.fix-header .ui.menu.desktop-menu a.active.item {
    background-color: #0c0033;
    border-color: white;
}

.retailer-nav.fix-header .app-logo-wrapper > img {
    width: 50%;
    height: auto;
}

.ui.vertical.menu .dropdown.item .menu {
    bottom: auto !important;
    left: 1% !important;
}

.mobile-menu .ui.vertical.menu .dropdown.item .menu {
    left: 1% !important;
}

@media (max-width: 991px) {
    .retailer-nav {
        position: fixed;
        width: 100%;
    }

    .retailer-nav.fix-header .user-icon-wrapper i.icon {
        color: #0c0033;
    }
    .ui.vertical.menu .dropdown.item .menu {
        border-color: #747474;
    }

    .retailer-nav .account-item .ui.dropdown .menu > .item.account-info {
        background: #d0deee !important;
    }
}

@media (min-width: 992px) {
    .retailer-nav .account-item .ui.dropdown .menu.account-menu {
        left: unset;
        right: 0;
    }
    .retailer-nav .app-logo-wrapper .retailer-name-info {
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
