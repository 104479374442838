.total-purchase-YTD-PP-widget-content > table {
    width: 100%;
}

.total-purchase-YTD-PP-widget-content .game-info > div {
    display: inline;
}

.total-purchase-YTD-PP-widget-content .game-info > .price-point {
    width: 9%;
    margin-right: 20%;
    font-size: 10px;
}

.total-purchase-YTD-PP-widget-content .total-percentage-bar {
    background-color: #4786ce;
    color: #9d9d9d;
    padding: 0 5px;
    border-radius: 5px;
    height: 25px;
}

.header {
    font-size: 16px !important;
    font-family: 'Open Sans', serif !important;
    font-weight: 700 !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.bar-text {
    color: #ffffff;
    font-weight: bold;
    min-width: 7%;
    text-align: right;
    font-size: 15px;
}
